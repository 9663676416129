import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css', "/node_modules/font-awesome/css/font-awesome.css"]
})
export class WorkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
