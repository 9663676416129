
<!-- STRAT NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img   src="assets/images/logo.png">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" >
          <a routerLink="/"  class="nav-link">Inicio</a>
        </li>
		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>PRODUCTOS Y SERVICIOS</a>
    <div ngbDropdownMenu >
			  <li ngbDropdown class="nav-item">
    <a href (click)="false" class="nav-link" ngbDropdownToggle>Productos IMPADOC</a>
    <div ngbDropdownMenu>
		<a routerLink="/interiores"  class="nav-link">Sistema de Acabado Para Interiores</a>
		<a routerLink="/drywall"  class="nav-link">Sistema de Acabado Para Drywall</a>
		<a routerLink="/exteriores"  class="nav-link">Sistema de Acabado Para Exteriores</a>
		<a routerLink="/adhesivos"  class="nav-link">Adhesivos Para Empaque</a>
    </div>
	
  </li>
		<a routerLink="/proyectos"  class="nav-link">Gestión de Proyectos</a>
		<a routerLink="/obra-civil"  class="nav-link">Obra Civil</a>
		<a routerLink="/acabados"  class="nav-link">Acabados</a>
		<a routerLink="/maquinaria"  class="nav-link">Alquiler de Maquinaria</a>
		<a routerLink="/suministro"  class="nav-link">Suministro de Material</a>
    </div>
  </li>
		  		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>EXPERIENCIA</a>
    <div ngbDropdownMenu >
		<a routerLink="/experiencia-obra"  class="nav-link">Obra Civil</a>
		<a routerLink="/experiencia-acabados"  class="nav-link">Acabados</a>
		<a routerLink="/experiencia-movimiento-tierra"  class="nav-link">Movimiento de Tierra</a>
    </div>
  </li>
        <li class="nav-item" >
          <a routerLink="/contactanos"  class="nav-link">CONTACTANOS</a>
        </li>	
        <li class="nav-item" >
          <a routerLink="/nuestra-empresa"  class="nav-link">NUESTRA EMPRESA</a>
        </li>	
      </ul>
    </div>
  </div>
</nav>
<br>
<br>
  <!--START HOME-->
  <section class="section bg-light" id="home">
    <div class="container">
      <div class="row">
	  <div class="col-sm-12 " >
				<img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/contacto.jpg">
		</div>
      </div>

	  <div class="row">
	  <div class="col-sm-6 " >
		<div class="row text-center">
		<div class="col-sm-12" >
			<h2 class="elementor-heading-title centered">ENVÍANOS UN MENSAJE</h2>
                                            <form>
                                                <div class="container text-center">
                                                    <div class="row">
                                                        <div class="col-sm">
                                                            <label for="firstname">Nombre</label>
                                                            <input type="text" class="form-control" name="firstname" id="firstname" name="firstname"   placeholder="">
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm">
                                                            <label for="secondname">Número Contacto</label>
                                                            <input  type="text" class="form-control" id="secondname" name="secondname"   placeholder="">
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm">
                                                            <label for="email">Correo Electrónico</label>
                                                            <input  type="text" class="form-control" id="email" name="email"   placeholder="">
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm">
                                                            <label for="mensaje">Comentario o mensaje</label>
                                                            <textarea style="height:100px"  type="text" class="form-control" id="mensaje" name="mensaje"   placeholder=""></textarea>
                                                        </div>
                                                    </div>
													
                                                    <div class="row">
                                                        <div class="col-sm">
														    <label><input type="checkbox" id="acepto" >Acepto Términos y Condiciones</label><br>
                                                        </div>
                                                    </div>
													<br>
                                                    <div class="row">
                                                        <div class="col-sm">
														    <button  class="btn btn-secondary btn-lg" (click)="checkData()">Enviar</button>
                                                        </div>
                                                    </div>												
                                                </div>

                                            </form>
											<br><br>

		</div>
		

      </div>

		  		</div>
					  	  <div class="col-sm-6" >
			<img class="disp" style='width: 100%; object-fit: contain' src="assets/images/soporte.png">
		  </div>
</div>	
</div>
  </section>
  <!--END HOME-->
  
       <div class="container-fluid">
       
	   <div class="row text-center align-middle" >
         <div class="col-sm-6 text-center " style="background-color: #5D5552;
   height:400px;background-position: center center;" >
   
   <div class="row text-center" style="height:100px;" >
   </div>
   <div class="row text-center " >
   
	   <div class="col-sm-12">
	   <i aria-hidden="true" class="fas fa-map-marker-alt" style="color:#e6bb39;font-size:70px"></i>
	   </div>
	   
   </div>
   
   <br><br>
      <div class="row text-center " >
	<div class="col-sm-12">
	   <h2 class="elementor-heading-title centered" style="color:#e6bb39">COMO LLEGAR?</h2>
	   </div>
  
   </div>
   

   
   
         </div>
		 <div class="col-sm-6 text-center">
			<iframe  style="height:400px; width:100%" id="gmap_canvas" src="https://maps.google.com/maps?q=Cl.%2047%20%2377-47%2C%20Rionegro%2C%20Antioquia&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
		 </div>
		 
		 
       </div>
     </div>

  <app-footer></app-footer>

