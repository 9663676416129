<!-- STRAT NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img   src="assets/images/logo.png">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" >
          <a routerLink="/"  class="nav-link">Inicio</a>
        </li>
		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>PRODUCTOS Y SERVICIOS</a>
    <div ngbDropdownMenu >
			  <li ngbDropdown class="nav-item">
    <a href (click)="false" class="nav-link" ngbDropdownToggle>Productos IMPADOC</a>
    <div ngbDropdownMenu>
		<a routerLink="/interiores"  class="nav-link">Sistema de Acabado Para Interiores</a>
		<a routerLink="/drywall"  class="nav-link">Sistema de Acabado Para Drywall</a>
		<a routerLink="/exteriores"  class="nav-link">Sistema de Acabado Para Exteriores</a>
		<a routerLink="/adhesivos"  class="nav-link">Adhesivos Para Empaque</a>
    </div>
	
  </li>
		<a routerLink="/proyectos"  class="nav-link">Gestión de Proyectos</a>
		<a routerLink="/obra-civil"  class="nav-link">Obra Civil</a>
		<a routerLink="/acabados"  class="nav-link">Acabados</a>
		<a routerLink="/maquinaria"  class="nav-link">Alquiler de Maquinaria</a>
		<a routerLink="/suministro"  class="nav-link">Suministro de Material</a>
    </div>
  </li>
		  		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>EXPERIENCIA</a>
    <div ngbDropdownMenu >
		<a routerLink="/experiencia-obra"  class="nav-link">Obra Civil</a>
		<a routerLink="/experiencia-acabados"  class="nav-link">Acabados</a>
		<a routerLink="/experiencia-movimiento-tierra"  class="nav-link">Movimiento de Tierra</a>
    </div>
  </li>
        <li class="nav-item" >
          <a routerLink="/contactanos"  class="nav-link">CONTACTANOS</a>
        </li>	
        <li class="nav-item" >
          <a routerLink="/nuestra-empresa"  class="nav-link">NUESTRA EMPRESA</a>
        </li>	
      </ul>
    </div>
  </div>
</nav>
<br>
<br>
<div appScrollspy [spiedTags]="['SECTION']">
   <!--START HOME-->
   <section class="section section bg-light" style="background-color:white !important" id="home">
      <div class="container-fluid">
         <div class="row" >
            <div class="col-sm-12 " >
               <img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/productos1.jpg">
            </div>
         </div>
         <br> <br>		
         <div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default">Adhesivos para Enchape, Cerámica y Porcelanato</h2>
                     <br>
                  </div>
               </div>
               <span style="color:black;font-size:30px">Impadoc presenta su nuevo sistema de instalación pegadoc, para cerámica y porcelanato, pegadoc contiene materias primas de alta calidad y aditivos de última tecnología con excelente fuerza de adhesión, aplicabilidad y resistencia.</span>
               <br> <br>	
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
		 
		  <div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default letra2">Pegadoc Cerámica</h2>
					  <br> <br>
                     <div class="section-title-border2"></div>
                     <br>
                  </div>
               </div>
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
         <div class="row text-left">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-4 letra" >
               <div class="elementor-element elementor-element-315bbe4 elementor-column elementor-col-50 elementor-inner-column" data-id="315bbe4" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-0cadcb0 elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-text-editor" data-id="0cadcb0" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p><span style="color: #e6bb39;">Descripción</span></p><p>Pegadoc Cerámica &nbsp;es un adhesivo cementicio de colores gris o blanco, que contiene materias primas especiales, y mezclado con agua forma un material con excelente fuerza de adhesión utilizado para la instalación de revestimientos en pisos y paredes.</p><br><p><span style="color: #e6bb39;">Ventajas&nbsp;</span></p><ul><li>Excelente fuerza de adherencia y trabajabilidad.</li><li>Bajo costo y altos rendimientos.</li><li>Secado&nbsp;Rápido&nbsp;que permite rellenar y estucar en el mismo&nbsp;día&nbsp;optimizando el&nbsp;tiempo en la obra.</li><li>Mínimo deslizamiento en instalaciones verticales.</li><li>&nbsp;Permite realizar ajustes y correcciones durante las instalaciones.</li><li>&nbsp;De fácil aplicación.</li><li>&nbsp;No requiere remojar las baldosas.</li></ul><br><p><span style="color: #e6bb39;">Superficie de Aplicación</span></p><p>Pegadoc Cerámico: para instalción de revestimientos cerámicos cerámicos de media o alta absorción (no gres porcelánico).</p><p>Pegadoc Cerámico: para instalación de revestimiento cerámico de baja absorción.</p><p>Puede utilizarse en pisos y paredes.</p><p>Diseñado para uso en interiores y exteriores.</p><br><p><span style="color: #e6bb39;">Presentación</span></p><ul class="nav nav--pull"><li>Material en polvo</li><li>Saco de 25&nbsp;Kg</li></ul></div>
				</div>
				</div>
						</div>
			</div>
		</div>
            </div>
            <div class="col-sm-4 text-center" >
               <img style='height: 400px; width: 400px; object-fit: contain'src="assets/images/pegadoc1.png">
               <br><br>
               <a href="assets/documentos/Pegadoc-Cerámico-Impadoc.pdf" class=" btn btn-custom" style="border-style: solid;" download>
               <i aria-hidden="true" class="ics fas fa-download elementor-button-text" style="color:#5D5552;" ></i>
               <span class="elementor-button-text"> DESCARGAR FICHA TÉCNICA</span>
               </a>
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
         <br><br>


		<div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default letra2">Pegadoc Porcelanato</h2>
                     <div class="section-title-border2"></div>
                     <br>
                  </div>
               </div>
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
         <div class="row text-left">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-4 letra" >
<div class="elementor-element elementor-element-cf78b4d elementor-column elementor-col-50 elementor-inner-column" data-id="cf78b4d" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-e4d6790 elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-text-editor" data-id="e4d6790" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p><span style="color: #e6bb39;">Descripción</span></p><p>Pegadoc Porcelanato Blanco es un adhesivo cementicio fabricado con agregados seleccionados y aditivos de alta tecnología, mezclado con agua forma un material con excelente fuerza de adhesión, aplicabilidad, humectabilidad y resistencia, para la instalación de revestimientos de baja absorción en superficies verticales y horizontales.</p><br><p><span style="color: #e6bb39;">Ventajas&nbsp;</span></p><ul><li>Excelente fuerza de adherencia y trabajabilidad.</li><li>Bajo costo y alto rendimiento.</li><li>Producto premezclado listo para usar.</li><li>Mínimo deslizamiento en instalaciones verticales.</li><li>Permite realizar ajustes y correciones lo que le permiten obtener un buen acabado de su obra.</li><li>&nbsp;De fácil aplicación.</li><li>Gran cremosidad.</li><li>No requiere remojar las baldosas.</li></ul><br><p><span style="color: #e6bb39;">Superficie de Aplicación</span></p><ul><li>Diseñado para uso en interiores y exteriores.</li><li>Para instalación de revestimientos cerámico de baja absorción.</li><li>Puede utilizarse en pisos o paredes.<p>&nbsp;</p><span style="color: #e6bb39;">Presentación</span></li><li>Saco de 25&nbsp;Kg</li></ul></div>
				</div>
				</div>
						</div>
			</div>
		</div>
                  <br><br>

            </div>
            <div class="col-sm-4 text-center" >
               <img style='height: 400px; width: 400px; object-fit: contain'src="assets/images/pegadoc2.jpg">
               <br><br>
               <a href="assets/documentos/Pegadoc-Porcelanato-Impadoc.pdf" class=" btn btn-custom" style="border-style: solid;" download>
               <i aria-hidden="true" class="ics fas fa-download elementor-button-text" style="color:#5D5552;" ></i>
               <span class="elementor-button-text"> DESCARGAR FICHA TÉCNICA</span>
               </a>
            </div>
            <div class="col-sm-2" >
            </div>
         </div>

         <br><br>
      </div>
   </section>
        <div class="container-fluid" >
       
	   <div class="row text-center align-middle" >
         <div class="col-sm-12 text-center " style="background-color: #5D5552;
   height:400px;background-position: center center;" >
   
   <div class="row text-center" style="height:100px;" >
   </div>
   <div class="row text-center " >
   
	   <div class="col-sm-12">
	   <h2 class="elementor-heading-title centered">CONOCE MÁS DE IMPADOC</h2>
	   </div>
	   
   </div>
   
   <br><br>
      <div class="row text-center " >
   
	   <div class="col-sm-12" >
   			<a href="assets/documentos/Catálogo-PRODUCTOS-IMPADOC.pdf" class=" btn btn-custom" style="border-style: solid;" download>
			<i aria-hidden="true" class="ics fas fa-envelope elementor-button-text" style="color:#5D5552;" ></i>
			<span class="elementor-button-text contac"> DESCARGAR CATÁLOGO</span>
			</a>
			
	   </div>
	   
   </div>

   
         </div>
       </div>
     </div>
	 <section class="section" id="features">
  <div class="container">
  
   <div class="row text-center">
	   <div class="col-sm-12 ">
			<h2 class="elementor-heading-title elementor-size-default">Galeria de Videos</h2>
			<br>
			<div class="section-title-border margin-t-70"></div>
	   </div>
   </div>
  <br><br>
    <div class="row align-items-center">
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/GFI-82d07KY?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/9LuB5zniHBU?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/SqUwLMY4780?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
    </div>
	
	<div class="row align-items-center">
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/9qTiMt1MWI8?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/-uhGlP4uSjI?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/SrnAbkQWt-8?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
    </div>
	<br>

	<br>
  </div>
</section>
   <!--END HOME-->
   <app-footer></app-footer>
</div>