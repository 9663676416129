<!-- STRAT NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img   src="assets/images/logo.png">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" >
          <a routerLink="/"  class="nav-link">Inicio</a>
        </li>
		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>PRODUCTOS Y SERVICIOS</a>
    <div ngbDropdownMenu >
			  <li ngbDropdown class="nav-item">
    <a href (click)="false" class="nav-link" ngbDropdownToggle>Productos IMPADOC</a>
    <div ngbDropdownMenu>
		<a routerLink="/interiores"  class="nav-link">Sistema de Acabado Para Interiores</a>
		<a routerLink="/drywall"  class="nav-link">Sistema de Acabado Para Drywall</a>
		<a routerLink="/exteriores"  class="nav-link">Sistema de Acabado Para Exteriores</a>
		<a routerLink="/adhesivos"  class="nav-link">Adhesivos Para Empaque</a>
    </div>
	
  </li>
		<a routerLink="/proyectos"  class="nav-link">Gestión de Proyectos</a>
		<a routerLink="/obra-civil"  class="nav-link">Obra Civil</a>
		<a routerLink="/acabados"  class="nav-link">Acabados</a>
		<a routerLink="/maquinaria"  class="nav-link">Alquiler de Maquinaria</a>
		<a routerLink="/suministro"  class="nav-link">Suministro de Material</a>
    </div>
  </li>
		  		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>EXPERIENCIA</a>
    <div ngbDropdownMenu >
		<a routerLink="/experiencia-obra"  class="nav-link">Obra Civil</a>
		<a routerLink="/experiencia-acabados"  class="nav-link">Acabados</a>
		<a routerLink="/experiencia-movimiento-tierra"  class="nav-link">Movimiento de Tierra</a>
    </div>
  </li>
        <li class="nav-item" >
          <a routerLink="/contactanos"  class="nav-link">CONTACTANOS</a>
        </li>	
        <li class="nav-item" >
          <a routerLink="/nuestra-empresa"  class="nav-link">NUESTRA EMPRESA</a>
        </li>	
      </ul>
    </div>
  </div>
</nav>
<br>
<br>
<div appScrollspy [spiedTags]="['SECTION']">
  <!--START HOME-->
  <section class="section section bg-light" id="home">
    <div class="container-fluid">
      <div class="row" >
	  <div class="col-sm-12 " >
				<img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/proyectos.jpg">
		</div>
      </div>
	  <br> <br>		
	  <div class="row">
	  <div class="col-sm-2" >
	  </div>
	  <div class="col-sm-8 text-justify" >

<span style="color:black;font-size:30px">Evolución Constructiva ofrece a todos nuestros clientes un enfoque metódico para planificar y orientar todos los procesos durante los proyectos, buscando que, desde la fase inicial hasta la etapa final, se logre la optimización de recursos y tiempos para lograr los objetivos trazados, con desempeños eficientes que garanticen la viabilidad del proyecto.

Planificar, ejecutar y realizar la entrega de un proyecto, con aprovechamiento de los recursos, optimización de tiempos y calidad es la base para que nuestros clientes confíen en la gestión de nuestra empresa con sus proyectos.</span>
		  <br> <br>
<div class="section-title-border2 text-center"></div><br>	
<br> <br>	  
</div>
		  	  <div class="col-sm-2" >
	  </div>
</div>	

      <div class="row text-center">
	  <div class="col-sm-3" >
	  </div>
	  <div class="col-sm-6" >
			        <ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
				  <br> <br>
<div class="section-title-border2 text-center"></div><br><br>	
		</div>
			  <div class="col-sm-3" >
	  </div>
      </div>
</div>
  </section>
  <!--END HOME-->
  

  <app-footer></app-footer>
</div>
