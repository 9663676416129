import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css', "/node_modules/font-awesome/css/font-awesome.css"]
})
export class EmpresaComponent implements OnInit {

	firstname: string;
	secondname: string;
	email: string;

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

checkData(){ 
}

currentSection = 'home';

  constructor() { }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  ngOnInit() { window.scrollTo(0, 0);
	  this.currentSection = 'home';
	  window.scrollTo(0, 0);
  }

}
