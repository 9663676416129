import { Component, OnInit, ViewEncapsulation, ViewChild  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-acabados',
  templateUrl: './acabados.component.html',
  styleUrls: ['./acabados.component.css', "/node_modules/font-awesome/css/font-awesome.css"]
})
export class AcabadosComponent implements OnInit {



checkData(){ 
}

  constructor() { }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }
  
  ngOnInit() { window.scrollTo(0, 0);
  }

}
