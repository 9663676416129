<!-- STRAT NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img   src="assets/images/logo.png">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" >
          <a routerLink="/" [ngClass]="{'active':currentSection === 'home'}"  class="nav-link">Inicio</a>
        </li>
		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>PRODUCTOS Y SERVICIOS</a>
    <div ngbDropdownMenu >
			  <li ngbDropdown class="nav-item">
    <a href (click)="false" class="nav-link" ngbDropdownToggle>Productos IMPADOC</a>
    <div ngbDropdownMenu>
		<a routerLink="/interiores"  class="nav-link">Sistema de Acabado Para Interiores</a>
		<a routerLink="/drywall"  class="nav-link">Sistema de Acabado Para Drywall</a>
		<a routerLink="/exteriores"  class="nav-link">Sistema de Acabado Para Exteriores</a>
		<a routerLink="/adhesivos"  class="nav-link">Adhesivos Para Empaque</a>
    </div>
	
  </li>
		<a routerLink="/proyectos"  class="nav-link">Gestión de Proyectos</a>
		<a routerLink="/obra-civil"  class="nav-link">Obra Civil</a>
		<a routerLink="/acabados"  class="nav-link">Acabados</a>
		<a routerLink="/maquinaria"  class="nav-link">Alquiler de Maquinaria</a>
		<a routerLink="/suministro"  class="nav-link">Suministro de Material</a>
    </div>
  </li>
		  		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>EXPERIENCIA</a>
    <div ngbDropdownMenu >
		<a routerLink="/experiencia-obra"  class="nav-link">Obra Civil</a>
		<a routerLink="/experiencia-acabados"  class="nav-link">Acabados</a>
		<a routerLink="/experiencia-movimiento-tierra"  class="nav-link">Movimiento de Tierra</a>
    </div>
  </li>
        <li class="nav-item" >
          <a routerLink="/contactanos"  class="nav-link">CONTACTANOS</a>
        </li>	
        <li class="nav-item" >
          <a routerLink="/nuestra-empresa"  class="nav-link">NUESTRA EMPRESA</a>
        </li>	
      </ul>
    </div>
  </div>
</nav>
<br>
<br>
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <!--START HOME-->
  <section class="section section bg-light" id="home">
    <div class="container">
      <div class="row">
	  <div class="col-sm-12 " >
				<img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/empresa.jpg">
		</div>
      </div>
	  <br>
	  <div class="row">
	  <div class="col-sm-12 " >
		<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-69ffcb0 elementor-widget elementor-widget-spacer" data-id="69ffcb0" data-element_type="widget" data-widget_type="spacer.default">
				<div class="elementor-widget-container">
					<div class="elementor-spacer">
			<div class="elementor-spacer-inner"></div>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">Quienes Somos</h2>		
			<div class="section-title-border2"></div><br>
			</div>
			
				</div>
				<div class="elementor-element elementor-element-6a9d119 elementor-widget elementor-widget-divider animated fadeInLeft" data-id="6a9d119" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="divider.default">
				<div class="elementor-widget-container">
					<div class="elementor-divider">
			<span class="elementor-divider-separator">
						</span>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-9aa5161 elementor-hidden-desktop elementor-widget elementor-widget-text-editor" data-id="9aa5161" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p style="text-align: justify; color:black !important;font-size:20px;">Somos una empresa integral en la búsqueda de soluciones para el desarrollo de proyectos en el área civil, nuestro equipo humano consta de profesionales altamente capacitados y mano de obra calificada.&nbsp;<span style="letter-spacing: 0em;">Somos distribuidores autorizados de productos listos para aplicar. Con el fin de brindar un servicio más completo&nbsp; préstamos el servicio de alquiler de maquinaria pesada y suministramos materiales de playa&nbsp; para la realización de diferentes actividades de construcción.</span></p></div>
				</div>
				</div>
				<div class="elementor-element elementor-element-0d67c0d elementor-widget elementor-widget-spacer" data-id="0d67c0d" data-element_type="widget" data-widget_type="spacer.default">
				<div class="elementor-widget-container">
					<div class="elementor-spacer">
			<div class="elementor-spacer-inner"></div>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-36ae785 elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="36ae785" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">Misión</h2>		
						<div class="section-title-border2"></div><br>
			</div>
				</div>
				<div class="elementor-element elementor-element-10717c6 elementor-widget elementor-widget-divider animated fadeInLeft" data-id="10717c6" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="divider.default">
				<div class="elementor-widget-container">
					<div class="elementor-divider">
			<span class="elementor-divider-separator">
						</span>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-507e6d2 elementor-hidden-desktop elementor-widget elementor-widget-text-editor" data-id="507e6d2" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p style="text-align: justify; color:black !important;font-size:20px;">Contribuir al desarrollo de obras civiles reduciendo tiempos de ejecución,&nbsp;<span style="letter-spacing: 0em;">con diseños innovadores y seguridad constructiva, poniendo al servicio&nbsp;</span><span style="letter-spacing: 0em;">de entidades públicas y privadas toda nuestra capacidad humana y técnica; ofrecido una amplia gama de servicios y productos de primera calidad, con alternativas innovadoras y a precios competitivos.</span></p></div>
				</div>
				</div>
				<div class="elementor-element elementor-element-832a560 elementor-widget elementor-widget-spacer" data-id="832a560" data-element_type="widget" data-widget_type="spacer.default">
				<div class="elementor-widget-container">
					<div class="elementor-spacer">
			<div class="elementor-spacer-inner"></div>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-1674f9b elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1674f9b" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h2 class="elementor-heading-title elementor-size-default">Visión</h2>		
						<div class="section-title-border2"></div><br>
			</div>
				</div>
				<div class="elementor-element elementor-element-5d64a80 elementor-widget elementor-widget-divider animated fadeInLeft" data-id="5d64a80" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeInLeft&quot;}" data-widget_type="divider.default">
				<div class="elementor-widget-container">
					<div class="elementor-divider">
			<span class="elementor-divider-separator">
						</span>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-0de7175 elementor-hidden-desktop elementor-widget elementor-widget-text-editor" data-id="0de7175" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p style="text-align: justify; color:black !important;font-size:20px;">Posicionarnos como una de las mejores empresas prestadoras de servicio integral en el área de la construcción, capaces de aportar y mejorar los procesos de nuestros clientes. Conformada por personas integras, honestas, profesionales en sus labores, con capacidades de servicio y compromiso.</p></div>
				</div>
				</div>
				<div class="elementor-element elementor-element-a4dd9ca elementor-widget elementor-widget-spacer" data-id="a4dd9ca" data-element_type="widget" data-widget_type="spacer.default">
				<div class="elementor-widget-container">
					<div class="elementor-spacer">
			<div class="elementor-spacer-inner"></div>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-49f7a1c elementor-widget elementor-widget-spacer" data-id="49f7a1c" data-element_type="widget" data-widget_type="spacer.default">
				<div class="elementor-widget-container">
					<div class="elementor-spacer">
			<div class="elementor-spacer-inner"></div>
		</div>
				</div>
				</div>
						</div>
			</div>

		  		</div>
</div>	
</div>
<br>
  </section>
  <!--END HOME-->
  

  <app-footer></app-footer>
</div>
