<!--START FEATURES -->
<section class="section" id="features">
  <div class="container">
  
   <div class="row text-center">
	   <div class="col-sm-12 ">
			<h2 class="elementor-heading-title elementor-size-default">LÍNEA DE NEGOCIOS</h2>
			<br>
			<div class="section-title-border margin-t-70"></div>
	   </div>
   </div>
  <br><br>
    <div class="row align-items-center">
      <div class="col-sm-6 text-center">
		<img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/maquinaria1.jpg">
	    <a href="/maquinaria" class=" btn btn-custom margin-t-30">CONOCE MÁS</a>
      </div>
      <div class="col-sm-6 text-center">
		<img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/suministro_material.jpg">
	    <a href="/suministro" class=" btn btn-custom margin-t-30">CONOCE MÁS</a>
      </div>
    </div>
	<br>
	<div class="row align-items-center">
      <div class="col-sm-6 text-center">
		<img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/ejecución_obra.jpg">
	    <a href="/obra-civil" class=" btn btn-custom margin-t-30">CONOCE MÁS</a>
      </div>
      <div class="col-sm-6 text-center">
		<img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/acabados.jpg">
	    <a href="/acabados" class=" btn btn-custom margin-t-30">CONOCE MÁS</a>
      </div>
    </div>
	<br>
	<div class="row align-items-center">
      <div class="col-sm-6 text-center">
		<img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/productos.jpg">
	    <a href="/productos" class=" btn btn-custom margin-t-30">CONOCE MÁS</a>
      </div>
      <div class="col-sm-6 text-center">
		<img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/gestion.jpg">
	    <a href="/proyectos" class=" btn btn-custom margin-t-30">CONOCE MÁS</a>
      </div>
    </div>	
	<br>
  </div>
</section>
<!--END FEATURES-->
