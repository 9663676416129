import { Component, OnInit, ViewEncapsulation, ViewChild  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-suministro',
  templateUrl: './suministro.component.html',
  styleUrls: ['./suministro.component.css', "/node_modules/font-awesome/css/font-awesome.css"]
})
export class SuministroComponent implements OnInit {



checkData(){ 
}

  constructor() { }
images = ["assets/images/suministro1.png", "assets/images/suministro2.png"];

paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }
  
  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

   togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  ngOnInit() { window.scrollTo(0, 0);
  }

}
