<!-- STRAT NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img   src="assets/images/logo.png">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" >
          <a routerLink="/"  class="nav-link">Inicio</a>
        </li>
		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>PRODUCTOS Y SERVICIOS</a>
    <div ngbDropdownMenu >
			  <li ngbDropdown class="nav-item">
    <a href (click)="false" class="nav-link" ngbDropdownToggle>Productos IMPADOC</a>
    <div ngbDropdownMenu>
		<a routerLink="/interiores"  class="nav-link">Sistema de Acabado Para Interiores</a>
		<a routerLink="/drywall"  class="nav-link">Sistema de Acabado Para Drywall</a>
		<a routerLink="/exteriores"  class="nav-link">Sistema de Acabado Para Exteriores</a>
		<a routerLink="/adhesivos"  class="nav-link">Adhesivos Para Empaque</a>
    </div>
	
  </li>
		<a routerLink="/proyectos"  class="nav-link">Gestión de Proyectos</a>
		<a routerLink="/obra-civil"  class="nav-link">Obra Civil</a>
		<a routerLink="/acabados"  class="nav-link">Acabados</a>
		<a routerLink="/maquinaria"  class="nav-link">Alquiler de Maquinaria</a>
		<a routerLink="/suministro"  class="nav-link">Suministro de Material</a>
    </div>
  </li>
		  		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>EXPERIENCIA</a>
    <div ngbDropdownMenu >
		<a routerLink="/experiencia-obra"  class="nav-link">Obra Civil</a>
		<a routerLink="/experiencia-acabados"  class="nav-link">Acabados</a>
		<a routerLink="/experiencia-movimiento-tierra"  class="nav-link">Movimiento de Tierra</a>
    </div>
  </li>
        <li class="nav-item" >
          <a routerLink="/contactanos"  class="nav-link">CONTACTANOS</a>
        </li>	
        <li class="nav-item" >
          <a routerLink="/nuestra-empresa"  class="nav-link">NUESTRA EMPRESA</a>
        </li>	
      </ul>
    </div>
  </div>
</nav>
<br>
<br>
<div appScrollspy [spiedTags]="['SECTION']">
  <!--START HOME-->
  <section class="section section bg-light" id="home">
	<div class="container-fluid">
	  <div class="row">
	  <div class="col-sm-12 " >
	  	  <div class="col-sm-12 " >
				<img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/politica.png">
		</div>
	   </div>
	   
	    </div>
    </div>
    <div class="container">
	  <div class="row text-justify letras">
	  <div class="col-sm-12 " >
	  <div class="elementor-text-editor elementor-clearfix"><p style="margin-top: 0cm; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;"><span style="font-size: 15.0pt; font-family: 'AvenirLTStdLight',serif; color: #333333;">&nbsp;</span></p>
<p>El presente documento tiene como finalidad reglamentar las políticas y procedimientos que serán aplicables en le manejo de la información de datos personales por parte de la empresa, en cumplimiento de las disposiciones contenidas en la Ley 1581 de 2012 y el Decreto 1377 de 2013, en vista de que&nbsp;<b>EVOLUCIÓN CONSTRUCTIVA S.A.S.&nbsp;</b>recopila datos personales en desarrollo de su objeto social. Por lo tanto, se pone en conocimiento de os Titulares de los Datos Personales que sean tratados de cualquier manera por la compañía, la presente Política de Tratamiento de Datos de Datos Personales, con el fin de que estos conozcan el objeto del tratamiento, los derechos, el procedimiento y los mecanismos que existen a su disposición para hacer efectivos sus derechos.</p>
<p></p>
<p><b>Responsables del Tratamiento</b></p>
<p>La empresa es la responsable del Tratamiento de la Base de Datos y será así mismo la responsable de peticiones, quejas, reclamos y consultas de todo tipo relacionadas con los datos personales. El encargado del Tratamiento de Datos Personales, para todos los efectos legales, es la empresa. Los datos de la empresa, como responsable del Tratamiento, son los siguientes:</p>
<p>Dirección: Calle 47 # 77 – 47</p>
<p>Correo Electrónico:&nbsp;<a href="mailto:contacto@evolucionconstructiva.co">contacto@evolucionconstructiva.co</a></p>
<p>Teléfono: 311 779 15 75</p>
<p></p>
<p><b>Finalidad del Tratamiento</b></p>
<p>La información que recolecta&nbsp;<b>EVOLUCIÓN CONSTRUCTIVA</b>&nbsp;en la prestación de sus servicios y en general en el desarrollo de su objeto social, es utilizada principalmente para identificar, mantener un registro y control de proveedores, clientes, accionistas, contratistas, empleados de la empresa con&nbsp; el fin de dar cumplimiento a las obligaciones que deriven de la relación legal, contractual o de cualquier índole que se tenga con la empresa, así como la exigibilidad de los derechos que se tengan partes en virtud de las mismas, para mantener informados a los Titulares de los Datos Personales respecto de los bienes y servicios prestados por&nbsp;<b>EVOLUCIÓN CONSTRUCTIVA</b>, y finalmente para transferir los Datos Personales a terceros designados por estas para la prestación de servicios ofrecidos y la exigibilidad de los derechos generados en virtud de la relación legal, contractual o comercial existente.</p>
<p></p>
<p><b>Tratamiento final de la Información</b></p>
<p>a. Envió y recepción de correspondencia.</p>
<p>b. Fines administrativos internos tales como: investigación de mercados, auditorias, reportes contables, análisis estadísticos y facturación.</p>
<p>c. Promocionar y publicitar nuestras actividades, productos y servicios.</p>
<p>d. Proveer los servicios y/o productos adquiridos directamente o con la participación de terceros.</p>
<p>e. Procesamiento e información de los Datos Personales.</p>
<p></p>
<p><b>Derechos del Titular</b></p>
<p>a. Acceder en forma gratuita a sus datos personales que haya sido objeto de tratamientos por parte del responsable.</p>
<p>b. Conocer, actualizar y rectificar sus datos personales frente al responsable, cuando los datos sean parciales, inexactos, incompletos, fraccionados, e induzcan a error. En todo caso el Titular se obliga a suministrar información veraz.</p>
<p>c. Solicitar prueba de la autorización otorgada al responsable del Tratamiento, salvo que expresamente se efectué como requisito para el tratamiento.</p>
<p>d. Ser informado, previa solicitud dirigida al responsable del Tratamiento, respecto del uso que se les han dado a los datos personales.</p>
<p>e. Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la Ley 1581 de 2012 y sus decretos reglamentarios y demás normas que la modifiquen, adicionen o complementen.</p>
<p>f.&nbsp; Revocar la autorización y/o solicitar la supresión de sus Datos Personales de las Bases de Datos de la empresa cuando en el tratamiento no se respeta los principios, derechos y garantías.</p>
<p></p>
<p><b>Aceptación</b></p>
<p>Los titulares de la información aceptan el tratamiento de sus datos conforme a los términos de esta política, al momento de proporcionar sus datos.</p>
</div>
	  
	</div>
</div>	
</div>
  </section>
  <!--END HOME-->

  <app-footer></app-footer>
</div>
