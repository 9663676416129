import { Component, OnInit, ViewEncapsulation, ViewChild  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  currentSection = 'home';
  images = ["assets/images/hotel-san-antonio-8.jpg", "assets/images/apartamentos-piedra-verde-5.jpg",
  "assets/images/apartamentos-piedra-verde-2.jpg", "assets/images/torre-andalucia-apartamentos-1.jpg",
  "assets/images/unidad-deportiva-maderos-5.jpg", "assets/images/apartamentos-terracota-4.jpg"
  , "assets/images/apartamentos-terracota-2.jpg","assets/images/apartamentos-terracota-7.jpg",
  "assets/images/apartamentos-san-marcos-5.jpg"];
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  
  constructor(private modalService: NgbModal) {
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

@ViewChild('carousel', {static : true}) carousel: NgbCarousel;

   togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }
  

  ngOnInit() { 
	window.scrollTo(0, 0);
	  this.currentSection = 'home';
  }

  /**
   * Open modal for show the video
   * @param content content of modal
   */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg' });
  }

}
