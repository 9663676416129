import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Index1Component } from './index1/index1.component';
import { LoginComponent } from './login/login.component';
import { PoliticaComponent } from './politica/politica.component';
import { ContactoComponent } from './contacto/contacto.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { Obra1Component } from './obra1/obra1.component';
import { AcabadosComponent } from './acabados/acabados.component';
import { MaquinariaComponent } from './maquinaria/maquinaria.component';
import { SuministroComponent } from './suministro/suministro.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { InterioresComponent } from './interiores/interiores.component';
import { DrywallComponent } from './drywall/drywall.component';
import { ExterioresComponent } from './exteriores/exteriores.component';
import { AdhesivosComponent } from './adhesivos/adhesivos.component';
import { ExperienciaObraComponent } from './experienciaobra/experienciaobra.component';
import { ProductosComponent } from './productos/productos.component';
import { BlogComponent } from './blog/blog.component';

import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import { SignupComponent } from './signup/signup.component';
import { ExperienciaAcabadosComponent } from './experienciaacabados/experienciaacabados.component';
import { ExperienciaTierraComponent } from './experienciatierra/experienciatierra.component';
import { ExperienciaComponent } from './experiencia/experiencia.component';
const routes: Routes = [
  {
    path: '',
    component: Index1Component
  },
    { path: 'politica', component: PoliticaComponent  },
	{ path: 'contactanos', component: ContactoComponent  },
	{ path: 'nuestra-empresa', component: EmpresaComponent  },
	{ path: 'obra-civil', component: Obra1Component  },
	{ path: 'acabados', component: AcabadosComponent  },
	{ path: 'maquinaria', component: MaquinariaComponent  },
	{ path: 'suministro', component: SuministroComponent  },
	{ path: 'proyectos', component: ProyectosComponent  },
	{ path: 'interiores', component: InterioresComponent  },
	{ path: 'drywall', component: DrywallComponent  },
	{ path: 'exteriores', component: ExterioresComponent  },
	{ path: 'adhesivos', component: AdhesivosComponent  },
	{ path: 'experiencia-obra', component: ExperienciaObraComponent  },
	{ path: 'experiencia-acabados', component: ExperienciaAcabadosComponent  },
	{ path: 'experiencia-movimiento-tierra', component: ExperienciaTierraComponent  },
	{ path: 'productos', component: ProductosComponent  },
	{ path: 'experiencia', component: ExperienciaComponent  },
	{ path: 'blog', component: BlogComponent  },
    { path: '**',  redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }

