
     <div class="container-fluid">
       
	   <div class="row text-center align-middle" >
         <div class="col-sm-12 text-center " style="background-color: #e6bb39;
   height:400px;background-position: center center;" >
   
   <div class="row text-center" style="height:100px;" >
   </div>
   <div class="row text-center " >
   
	   <div class="col-sm-12">
	   <h2 class="elementor-heading-title centered">QUIERO AGENDAR UNA CITA</h2>
	   </div>
	   
   </div>
   
   <br><br>
      <div class="row text-center " >
   
	   <div class="col-sm-12" >
   			<a href="/contactanos" class=" btn btn-custom" style="border-style: solid;">
			<i aria-hidden="true" class="ics fas fa-envelope elementor-button-text" style="color:#5D5552;" ></i>
			<span class="elementor-button-text contac"> CONTÁCTANOS</span>
			</a>
	   </div>
	   
   </div>
   

   
   
         </div>
       </div>
     </div>

     <div class="container-fluid">
       
	   <div class="row text-center align-middle" >
         <div class="col-sm-12 text-center " style="background-color: #5D5552;
   height:400px;background-position: center center;" >
   
   <div class="row text-center" style="height:100px;" >
   </div>
   <div class="row text-center " >
   
	   <div class="col-sm-12">
	   <h2 class="elementor-heading-title centered contac3">CONOCE MÁS DE EVOLUCIÓN CONSTRUCTIVA</h2>
	   </div>
	   
   </div>
   
   <br><br>
      <div class="row text-center " >
   
	   <div class="col-sm-12" >
   			<a href="assets/images/Brouchure.pdf" class=" btn btn-custom" style="border-style: solid;" download>
			<i aria-hidden="true" class="ics fas fa-download elementor-button-text" style="color:#5D5552;" ></i>
			<span class="elementor-button-text contac contac4"> DESCARGAR BROUCHERE</span>
			</a>
	   </div>
	   
   </div>
         </div>
       </div>
     </div>


     <div class="container-fluid">
       
	   <div class="row text-center align-middle" >
         <div class="col-sm-12 text-center " style="background-color: #e6bb39;
   background-position: center center;" >
   
   <div class="row text-center" style="height:100px;" >
   </div>
   

            <div class="row text-center " >
   
		   <div class="col-sm-3" >
				<div class="row" >
				<div class="col-sm-1" >
				<span class="" >
				<i aria-hidden="true" class="fas fa-map-marker-alt" style="font-size: 50px;"></i>				</span>
					
				</div>
				<div class="col-sm-11" >
					<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span>Dirección: </span>
				</h3>
								<p class="elementor-icon-box-description">Calle 47 # 77 - 47
<br>Barrio Porvenir <br> Rionegro</p>
							</div>
				</div>
				</div>
		   </div>
		   		   <div class="col-sm-3" >
				<div class="row" >
				<div class="col-sm-1" >
				<span class="" >
				<i aria-hidden="true" class="far fa-clock" style="font-size: 50px;"></i>				</span>
					
				</div>
				<div class="col-sm-11" >
					<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span>Horario de Atención: </span>
				</h3>
								<p class="elementor-icon-box-description">Lun–Vier: 8:00 a.m a 5:30 p.m<br>Sab: 8:00 a.m a 1:00 p.m</p>
							</div>
				</div>
				</div>
		   </div>
	   		   		   <div class="col-sm-3" >
				<div class="row" >
				<div class="col-sm-1" >
				<span class="" >
				<i aria-hidden="true" class="fas fa-phone	" style="font-size: 40px;"></i>				</span>
					
				</div>
				<div class="col-sm-11" >
					<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span>Línea de atención: </span>
				</h3>
								<p class="elementor-icon-box-description">+(4) 614 57 08<br>     311 779 15 75</p>
							</div>
				</div>
				</div>
		   </div>
		   <div class="col-sm-3" >
				<div class="row" >
				<div class="col-sm-1" >
				<span class="" >
				<i aria-hidden="true" class="fas fa-envelope	" style="font-size: 50px;"></i>				</span>
					
				</div>
				<div class="col-sm-11" >
					<div class="elementor-icon-box-content">
				<h3 class="elementor-icon-box-title">
					<span>E-mail: </span>
				</h3>
								<p class="elementor-icon-box-description">contacto@evolucionconstructiva.co</p>
							</div>
				</div>
				</div>
		   </div>
		</div>
   

   
   
         </div>
       </div>
     </div>


<!--START FOOTER ALTER-->
<div class="footer-alt">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="float-left pull-none">
                    <p class="copy-rights text-white mb-3 mb-sm-0">{{ cur_year }} © Evolución Constructiva
					 					<a href="/politica" class="" style="color:#e6bb39">Politica de datos</a>
					</p>
                </div>
                <div class="float-right pull-none ">
                    <ul class="list-inline social m-0">
					<li class="list-inline-item"><a href="https://twitter.com/evoconstructiva" class="social-icon ml-1"><i class="mdi mdi-twitter"></i></a></li>
					<li class="list-inline-item"><a href="https://www.instagram.com/evolucionconstructiva/" class="social-icon ml-1"><i class="mdi mdi-instagram"></i></a></li>
					<!--
                        <li class="list-inline-item"><a href="#" class="social-icon ml-1"><i class="mdi mdi-facebook"></i></a></li>
                        
                        <li class="list-inline-item"><a href="#" class="social-icon ml-1"><i class="mdi mdi-linkedin"></i></a></li>
                        <li class="list-inline-item"><a href="#" class="social-icon ml-1"><i class="mdi mdi-google-plus"></i></a></li>
                        <li class="list-inline-item"><a href="#" class="social-icon ml-1"><i class="mdi mdi-dribbble"></i></a></li>-->
                    </ul>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>
