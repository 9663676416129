<!-- STRAT NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img   src="assets/images/logo.png">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" >
          <a routerLink="/"  class="nav-link">Inicio</a>
        </li>
		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>PRODUCTOS Y SERVICIOS</a>
    <div ngbDropdownMenu >
			  <li ngbDropdown class="nav-item">
    <a href (click)="false" class="nav-link" ngbDropdownToggle>Productos IMPADOC</a>
    <div ngbDropdownMenu>
		<a routerLink="/interiores"  class="nav-link">Sistema de Acabado Para Interiores</a>
		<a routerLink="/drywall"  class="nav-link">Sistema de Acabado Para Drywall</a>
		<a routerLink="/exteriores"  class="nav-link">Sistema de Acabado Para Exteriores</a>
		<a routerLink="/adhesivos"  class="nav-link">Adhesivos Para Empaque</a>
    </div>
	
  </li>
		<a routerLink="/proyectos"  class="nav-link">Gestión de Proyectos</a>
		<a routerLink="/obra-civil"  class="nav-link">Obra Civil</a>
		<a routerLink="/acabados"  class="nav-link">Acabados</a>
		<a routerLink="/maquinaria"  class="nav-link">Alquiler de Maquinaria</a>
		<a routerLink="/suministro"  class="nav-link">Suministro de Material</a>
    </div>
  </li>
		  		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>EXPERIENCIA</a>
    <div ngbDropdownMenu >
		<a routerLink="/experiencia-obra"  class="nav-link">Obra Civil</a>
		<a routerLink="/experiencia-acabados"  class="nav-link">Acabados</a>
		<a routerLink="/experiencia-movimiento-tierra"  class="nav-link">Movimiento de Tierra</a>
    </div>
  </li>
        <li class="nav-item" >
          <a routerLink="/contactanos"  class="nav-link">CONTACTANOS</a>
        </li>	
        <li class="nav-item" >
          <a routerLink="/nuestra-empresa"  class="nav-link">NUESTRA EMPRESA</a>
        </li>	
      </ul>
    </div>
  </div>
</nav>
<br>
<br>
<div appScrollspy [spiedTags]="['SECTION']">
   <!--START HOME-->
   <section class="section section bg-light" id="home">
      <div class="container-fluid">
         <div class="row" >
            <div class="col-sm-12 " >
               <img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/blog2.jpg	">
            </div>
         </div>
         <br> <br>
		 <div class="row" >
		 <div class="col-sm-4 " >
		 </div>
            <div class="col-sm-4 " >
               <img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/casa.jpg">
            </div>
					 <div class="col-sm-4 " >
		 </div>
         </div>
		  <br>
         <div class="row text-center">
            <div class="col-sm-3" >
            </div>
            <div class="col-sm-6 text-center" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                     <h2 class="elementor-heading-title elementor-size-default text-center">Oportunidad de Vivienda en Colombia</h2>
                     <br>
					 <p class="letra3"> Mi Casa Ya es el programa del Gobierno nacional que facilita la compra de vivienda nueva urbana en Colombia. Está dirigido a familias con ingresos de hasta cuatro salarios mínimos, 
					 y consiste en otorgar un subsidio a la cuota inicial y cobertura a la tasa de interés, lo que se verá reflejado en una cuota mensual baja durante los primeros siete años. Ampliar información: <a href="https://www.fna.gov.co/vivienda/Subsidios-y-coberturas/mi-casa-ya">www.fna.gov.co/vivienda/Subsidios-y-coberturas/mi-casa-ya</a></p>
               </div>
               
               <br> <br>	
            </div>
            <div class="col-sm-3" >
            </div>
         </div>
         
		 		  <div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default letra2 text-center">Galería</h2>
					  <br> <br>
                     <br>
                  </div>
               </div>  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>

      </div>
	  <div class="container">
	  		       <div class="row" >
	  <div class="col-sm-12" >
        <ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images">
				<img style='height:100%; width:100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		</div>
      </div>
	   </div>
	   <br> <br>
   </section>
   <!--END HOME-->
   <app-footer></app-footer>
</div>