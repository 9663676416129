import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drywall',
  templateUrl: './drywall.component.html',
  styleUrls: ['./drywall.component.css', "/node_modules/font-awesome/css/font-awesome.css"]
})
export class DrywallComponent implements OnInit {

	firstname: string;
	secondname: string;
	email: string;

checkData(){ 
}

  constructor() { }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  ngOnInit() { window.scrollTo(0, 0);
  }

}
