import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-politica',
  templateUrl: './politica.component.html',
  styleUrls: ['./politica.component.css', "/node_modules/font-awesome/css/font-awesome.css"]
})
export class PoliticaComponent implements OnInit {

  constructor() { }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  ngOnInit() { window.scrollTo(0, 0);
  }

}
