import { Component, OnInit, ViewEncapsulation, ViewChild  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-experienciatierra',
  templateUrl: './experienciatierra.component.html',
  styleUrls: ['./experienciatierra.component.css', "/node_modules/font-awesome/css/font-awesome.css"]
})
export class ExperienciaTierraComponent implements OnInit {

  currentSection = 'home';
  images = ["assets/images/unidad-deportiva-piedras-blancas.jpg", "assets/images/unidad-deportiva-piedras-blancas-1.jpg",
  "assets/images/unidad-deportiva-piedras-blancas-2.jpg", "assets/images/unidad-deportiva-piedras-blancas-3.jpg",
  "assets/images/unidad-deportiva-piedras-blancas-4.jpg", "assets/images/unidad-deportiva-piedras-blancas-5.jpg",
  "assets/images/unidad-deportiva-piedras-blancas-6.jpg", "assets/images/unidad-deportiva-piedras-blancas-7.jpg",
  "assets/images/unidad-deportiva-piedras-blancas-8.jpg", "assets/images/unidad-deportiva-piedras-blancas-9.jpg"];
  
    images2 = ["assets/images/placa-deportiva-hojas-anchas-1.jpg", "assets/images/placa-deportiva-hojas-anchas-2.jpg",
  "assets/images/placa-deportiva-hojas-anchas-3.jpg", "assets/images/placa-deportiva-hojas-anchas-4.jpg",
  "assets/images/placa-deportiva-hojas-anchas-5.jpg", "assets/images/placa-deportiva-hojas-anchas-6.jpg"];
  
  
  images3 = ["assets/images/confama1.jpg", "assets/images/confama2.jpg",
  "assets/images/confama3.jpg"];
  
  
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;


checkData(){ 
}

  constructor() { }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }
  
  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

   togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
  

  ngOnInit() { window.scrollTo(0, 0);
  }

}
