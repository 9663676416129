import { Component, OnInit, ViewEncapsulation, ViewChild  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-experienciaacabados',
  templateUrl: './experienciaacabados.component.html',
  styleUrls: ['./experienciaacabados.component.css', "/node_modules/font-awesome/css/font-awesome.css"]
})
export class ExperienciaAcabadosComponent implements OnInit {

  currentSection = 'home';
  images = ["assets/images/hotel-san-antonio-1.jpg", "assets/images/hotel-san-antonio-2.jpg",
  "assets/images/hotel-san-antonio-5.jpg", "assets/images/hotel-san-antonio-6.jpg",
  "assets/images/hotel-san-antonio-7.jpg", "assets/images/hotel-san-antonio-8.jpg",
  "assets/images/hotel-san-antonio-9.jpg", "assets/images/hotel-san-antonio-10.jpg"];
  
    images2 = ["assets/images/apartamentos-piedra-verde-1.jpg", "assets/images/apartamentos-piedra-verde-2.jpg",
  "assets/images/apartamentos-piedra-verde-3.jpg", "assets/images/apartamentos-piedra-verde-4.jpg",
  "assets/images/apartamentos-piedra-verde-5.jpg", "assets/images/apartamentos-piedra-verde-6.jpg"];
  
  
  images3 = ["assets/images/torre-andalucia-apartamentos-1.jpg", "assets/images/torre-andalucia-apartamentos-2.jpg",
  "assets/images/torre-andalucia-apartamentos-3.jpg", "assets/images/torre-andalucia-apartamentos-4.jpg",
  "assets/images/torre-andalucia-apartamentos-5.jpg", "assets/images/torre-andalucia-apartamentos-6.jpg",
  "assets/images/torre-andalucia-apartamentos-7.jpg", "assets/images/torre-andalucia-apartamentos-8.jpg"];
  
    images4 = ["assets/images/apartamentos-terracota-1.jpg", "assets/images/apartamentos-terracota-2.jpg",
  "assets/images/apartamentos-terracota-3.jpg", "assets/images/apartamentos-terracota-4.jpg",
  "assets/images/apartamentos-terracota-5.jpg", "assets/images/apartamentos-terracota-6.jpg",
  "assets/images/apartamentos-terracota-7.jpg", "assets/images/apartamentos-terracota-8.jpg",];
  
      images5 = ["assets/images/apartamentos-san-marcos-1.jpg", "assets/images/apartamentos-san-marcos-2.jpg",
  "assets/images/apartamentos-san-marcos-3.jpg", "assets/images/apartamentos-san-marcos-4.jpg",
  "assets/images/apartamentos-san-marcos-5.jpg"];
  
        images6 = ["assets/images/cdi-casas-del-mar-1.jpg", "assets/images/cdi-casas-del-mar-2.jpg",
  "assets/images/cdi-casas-del-mar-3.jpg", "assets/images/cdi-casas-del-mar-4.jpg",
  "assets/images/cdi-casas-del-mar-5.jpg"];
  
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;


checkData(){ 
}

  constructor() { }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }
  
  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

   togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
  

  ngOnInit() { window.scrollTo(0, 0);
  }

}
