<!-- STRAT NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img   src="assets/images/logo.png">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" >
          <a routerLink="/"  class="nav-link">Inicio</a>
        </li>
		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>PRODUCTOS Y SERVICIOS</a>
    <div ngbDropdownMenu >
			  <li ngbDropdown class="nav-item">
    <a href (click)="false" class="nav-link" ngbDropdownToggle>Productos IMPADOC</a>
    <div ngbDropdownMenu>
		<a routerLink="/interiores"  class="nav-link">Sistema de Acabado Para Interiores</a>
		<a routerLink="/drywall"  class="nav-link">Sistema de Acabado Para Drywall</a>
		<a routerLink="/exteriores"  class="nav-link">Sistema de Acabado Para Exteriores</a>
		<a routerLink="/adhesivos"  class="nav-link">Adhesivos Para Empaque</a>
    </div>
	
  </li>
		<a routerLink="/proyectos"  class="nav-link">Gestión de Proyectos</a>
		<a routerLink="/obra-civil"  class="nav-link">Obra Civil</a>
		<a routerLink="/acabados"  class="nav-link">Acabados</a>
		<a routerLink="/maquinaria"  class="nav-link">Alquiler de Maquinaria</a>
		<a routerLink="/suministro"  class="nav-link">Suministro de Material</a>
    </div>
  </li>
		  		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>EXPERIENCIA</a>
    <div ngbDropdownMenu >
		<a routerLink="/experiencia-obra"  class="nav-link">Obra Civil</a>
		<a routerLink="/experiencia-acabados"  class="nav-link">Acabados</a>
		<a routerLink="/experiencia-movimiento-tierra"  class="nav-link">Movimiento de Tierra</a>
    </div>
  </li>
        <li class="nav-item" >
          <a routerLink="/contactanos"  class="nav-link">CONTACTANOS</a>
        </li>	
        <li class="nav-item" >
          <a routerLink="/nuestra-empresa"  class="nav-link">NUESTRA EMPRESA</a>
        </li>	
      </ul>
    </div>
  </div>
</nav>
<br>
<br>
<div appScrollspy [spiedTags]="['SECTION']">
   <!--START HOME-->
   <section class="section section bg-light" style="background-color:white !important" id="home">
      <div class="container-fluid">
         <div class="row" >
            <div class="col-sm-12 " >
               <img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/productos1.jpg">
            </div>
         </div>
         <br> <br>		
         <div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default">Sistema de Acabados para Exteriores</h2>
                     <br>
                  </div>
               </div>
               <span style="color:black;font-size:30px">Las fachadas y muros que están en contacto con la intemperie requieren de una protección especial para lo cual Impadoc ha desarrollado productos base cemento que garantizan mayor durabilidad y resistencia.</span>
               <br> <br>	
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
		 
		  <div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default letra2">Mortero Listo</h2>
					  <br> <br>
                     <div class="section-title-border2"></div>
                     <br>
                  </div>
               </div>
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
         <div class="row text-left">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-4 letra" >
               <div class="elementor-element elementor-element-559d09d elementor-column elementor-col-50 elementor-inner-column" data-id="559d09d" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-ee7ed75 elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-text-editor" data-id="ee7ed75" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p><span style="color: #e6bb39; font-family: 'Encode Sans Semi Expanded', sans-serif; font-size: 25px; font-weight: 400; letter-spacing: 0em;">Descripción</span></p><p>El morterolisto es un mortero seco premezclado sustituido del repello tradicional, es un mortero tipo S que permite nivelas superficies verticales y horizontales optimizado los tiempos en obra.</p><br><p><span style="color: #e6bb39;">Ventajas</span></p><ul><li>Fácil de aplicar, rápido y limpio</li><li>Secado mas rápido frente el repello tradicional</li><li>Mejora el control de materiales de obra</li><li>Bajo consumo de agua y desperdicio</li></ul><ul><li>Compatible con pesantes cerámicos, por lo cual permite pegar cerámica después de aplicado.</li></ul><p><br><span style="color: #e6bb39;">Superficie de Aplicación</span></p><ul><li>Ladrillo común, ladrillo farol, ladrillo estructural.</li><li>Bloques de hormigón, bloques prefabricados y placas de concentrado vaciado, en ambientes interiores y exteriores.</li></ul><p><br><span style="color: #e6bb39; font-family: 'Encode Sans Semi Expanded', sans-serif; font-size: 25px; font-weight: 400; letter-spacing: 0em;">Presentación</span></p><ul><li>Material en polvo</li><li>Saco de 40 Kg</li></ul></div>
				</div>
				</div>
						</div>
			</div>
		</div>
            </div>
            <div class="col-sm-4 text-center" >
               <img style='height: 400px; width: 400px; object-fit: contain'src="assets/images/mortero1.jpg">
               <br><br>
               <a href="assets/documentos/Morterolisto-Impadoc.pdf" class=" btn btn-custom" style="border-style: solid;" download>
               <i aria-hidden="true" class="ics fas fa-download elementor-button-text" style="color:#5D5552;" ></i>
               <span class="elementor-button-text"> DESCARGAR FICHA TÉCNICA</span>
               </a>
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
         <br><br>


		<div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default letra2">Rellenolisto Exteriores</h2>
                     <div class="section-title-border2"></div>
                     <br>
                  </div>
               </div>
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
         <div class="row text-left">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-4 letra" >
<div class="elementor-element elementor-element-e302f3d elementor-column elementor-col-50 elementor-inner-column" data-id="e302f3d" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-4aa7b86 elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-text-editor" data-id="4aa7b86" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p><span style="color: #e6bb39;">Descripción</span></p>
<p>El Rellenolisto Exteriores es un producto en polvo de color gris, constituido por minerales de alta pureza y aditivos de Última tecnología que le confieren características especiales para su óptimo desempeñó a la intemperie y economía.</p><br>
<p><span style="color: #e6bb39;">Ventajas</span></p>
<ul>
<li>El Rellenolisto exteriores puede ser usado como sustituto del repello tradicional con las ventajas de una aplicación más fácil, rápida y limpia.</li>
<li>Viene en dos presentaciones, gruesa y fina, las cuales ofrecen la opción al usuario de dar lleno o acabado en las superficies dependiendo su necesidad.</li>
<li>Debido a su formula avanzada, genera alto rendimiento para el aplicador y excelentes características mecánicas de los acabados y un gran desempeño a la intemperie.</li><br>
</ul>
<p><span style="color: #e6bb39;">Superficies de Aplicación</span></p>
<p>El Rellenolisto exteriores está diseñado para ser aplicado como relleno sobre fachadas, sobre diferente tipos de mampostería como ladrillo común, ladrillo farol, ladrillo estructural, bloques de hormigón, bloques prefabricados y placas de concreto vaciado.</p><br>
<p><span style="color: #e6bb39;">Presentación</span></p>
<ul><li>Material en polvo</li><li>Saco de 25&nbsp;Kg</li></ul><ul class="nav nav--pull">
</ul></div>
				</div>
				</div>
						</div>
			</div>
		</div>
                  <br><br>

            </div>
            <div class="col-sm-4 text-center" >
               <img style='height: 400px; width: 400px; object-fit: contain'src="assets/images/relleno1.jpg">
               <br><br>
               <a href="assets/documentos/RellenoListo-Exteriores-Impadoc.pdf" class=" btn btn-custom" style="border-style: solid;" download>
               <i aria-hidden="true" class="ics fas fa-download elementor-button-text" style="color:#5D5552;" ></i>
               <span class="elementor-button-text"> DESCARGAR FICHA TÉCNICA</span>
               </a>
            </div>
            <div class="col-sm-2" >
            </div>
         </div>

<div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default letra2">Impaextuco</h2>
					  <br> <br>
                     <div class="section-title-border2"></div>
                     <br>
                  </div>
               </div>
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
         <div class="row text-left">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-4 letra" >
               <div class="elementor-element elementor-element-4d049b9 elementor-column elementor-col-50 elementor-inner-column" data-id="4d049b9" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-1825df4 elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-text-editor" data-id="1825df4" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p><span style="color: #e6bb39;">Descripción</span></p><p>El Impaextuco es un estuco plástico en polvo para exteriores de color blanco diseñado, para dar acabados en la construcción. Es un material base cemento, fabricado con minerales de alta pureza y aditivos de última tecnología, asegurando acabados finos, durables y resistentes a la intemperie con mayor economía.</p><br><p><span style="color: #e6bb39;">Ventajas</span></p><ul><li>Resistente, con alta dureza y gran trabajabilidad,</li><li>Fácil de preparar y aplicar, requiere menor cantidad de agua para la mezcla.</li><li>Permite pulir y hacer llenos o resanes en fachadas.</li><li>Secado rápido frente a estucos plásticos tradicionales que optimiza tiempo de obra.</li><li>Permite hacer dilataciones, filos y carteras.</li><li>Al secar en una hora no se lava si llueve después de aplicado.</li><br></ul><p><span style="color: #e6bb39;">Superficie de Aplicación</span></p><p>Rellenolisto de Exteriores de Impadoc, revoques, repellos o pañetes. También puede ser aplicado sobre concreto vaciado y Fibrocemento.</p><br><p><span style="color: #e6bb39;">Presentación</span></p><ul><li>Material en polvo</li><li>Saco de 25&nbsp;Kg</li></ul></div>
				</div>
				</div>
						</div>
			</div>
		</div>
            </div>
            <div class="col-sm-4 text-center" >
               <img style='height: 400px; width: 400px; object-fit: contain'src="assets/images/estuco4.jpg">
               <br><br>
               <a href="assets/documentos/Impaextuco-Impadoc.pdf" class=" btn btn-custom" style="border-style: solid;" download>
               <i aria-hidden="true" class="ics fas fa-download elementor-button-text" style="color:#5D5552;" ></i>
               <span class="elementor-button-text"> DESCARGAR FICHA TÉCNICA</span>
               </a>
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
<br>


<div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default letra2">Impatextura</h2>
					  <br> <br>
                     <div class="section-title-border2"></div>
                     <br>
                  </div>
               </div>
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
         <div class="row text-left">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-4 letra" >
               <div class="elementor-element elementor-element-7433689 elementor-column elementor-col-50 elementor-inner-column" data-id="7433689" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-c285a5a elementor-hidden-tablet elementor-hidden-phone elementor-widget elementor-widget-text-editor" data-id="c285a5a" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p><span style="color: #e6bb39;">Descripción</span></p><p>El impatextura es un estuco de granulometria mixta que permite realizar un acabado texturizado de acuerdo a la creatividad del aplicador. Para uso en interiores y exteriores. Color blanco.</p><br><p><span style="color: #e6bb39;">Ventajas</span></p><ul><li>Fácil de aplicar, rapido y limpio.</li><li>Permite hacer diferentes texturas.</li><li>Gran resistencia a la intemperie.</li><li>No requiere base para su aplicaciòn.</li><li>Secado Rapido frente a productos similares.</li></ul><br><p><span style="color: #e6bb39;">Superficie de Aplicación</span></p><p>Rellenolisto de Exteriores de Impadoc, revoques, repellos o pañetes. También puede ser aplicado sobre concreto vaciado y Fibrocemento.</p><br><p><span style="color: #e6bb39;">Presentación</span></p><ul><li>Material en polvo</li><li>Saco de 25Kg</li></ul></div>
				</div>
				</div>
						</div>
			</div>
		</div>
            </div>
            <div class="col-sm-4 text-center" >
               <img style='height: 400px; width: 400px; object-fit: contain'src="assets/images/textura.jpg">
               <br><br>
               <a href="assets/documentos/Impatextura-Impadoc.pdf" class=" btn btn-custom" style="border-style: solid;" download>
               <i aria-hidden="true" class="ics fas fa-download elementor-button-text" style="color:#5D5552;" ></i>
               <span class="elementor-button-text"> DESCARGAR FICHA TÉCNICA</span>
               </a>
            </div>
            <div class="col-sm-2" >
            </div>
         </div>



         <br><br>
      </div>
   </section>
        <div class="container-fluid" >
       
	   <div class="row text-center align-middle" >
         <div class="col-sm-12 text-center " style="background-color: #5D5552;
   height:400px;background-position: center center;" >
   
   <div class="row text-center" style="height:100px;" >
   </div>
   <div class="row text-center " >
   
	   <div class="col-sm-12">
	   <h2 class="elementor-heading-title centered">CONOCE MÁS DE IMPADOC</h2>
	   </div>
	   
   </div>
   
   <br><br>
      <div class="row text-center " >
   
	   <div class="col-sm-12" >
   			<a href="assets/documentos/Catálogo-PRODUCTOS-IMPADOC.pdf" class=" btn btn-custom" style="border-style: solid;" download>
			<i aria-hidden="true" class="ics fas fa-envelope elementor-button-text" style="color:#5D5552;" ></i>
			<span class="elementor-button-text contac"> DESCARGAR CATÁLOGO</span>
			</a>
			
	   </div>
	   
   </div>

   
         </div>
       </div>
     </div>
	 <section class="section" id="features">
  <div class="container">
  
   <div class="row text-center">
	   <div class="col-sm-12 ">
			<h2 class="elementor-heading-title elementor-size-default">Galeria de Videos</h2>
			<br>
			<div class="section-title-border margin-t-70"></div>
	   </div>
   </div>
  <br><br>
    <div class="row align-items-center">
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/GFI-82d07KY?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/9LuB5zniHBU?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/SqUwLMY4780?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
    </div>
	
	<div class="row align-items-center">
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/9qTiMt1MWI8?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/-uhGlP4uSjI?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/SrnAbkQWt-8?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
    </div>
	<br>

	<br>
  </div>
</section>
   <!--END HOME-->
   <app-footer></app-footer>
</div>