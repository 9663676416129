<!-- STRAT NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img   src="assets/images/logo.png">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" >
          <a routerLink="/"  class="nav-link">Inicio</a>
        </li>
		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>PRODUCTOS Y SERVICIOS</a>
    <div ngbDropdownMenu >
			  <li ngbDropdown class="nav-item">
    <a href (click)="false" class="nav-link" ngbDropdownToggle>Productos IMPADOC</a>
    <div ngbDropdownMenu>
		<a routerLink="/interiores"  class="nav-link">Sistema de Acabado Para Interiores</a>
		<a routerLink="/drywall"  class="nav-link">Sistema de Acabado Para Drywall</a>
		<a routerLink="/exteriores"  class="nav-link">Sistema de Acabado Para Exteriores</a>
		<a routerLink="/adhesivos"  class="nav-link">Adhesivos Para Empaque</a>
    </div>
	
  </li>
		<a routerLink="/proyectos"  class="nav-link">Gestión de Proyectos</a>
		<a routerLink="/obra-civil"  class="nav-link">Obra Civil</a>
		<a routerLink="/acabados"  class="nav-link">Acabados</a>
		<a routerLink="/maquinaria"  class="nav-link">Alquiler de Maquinaria</a>
		<a routerLink="/suministro"  class="nav-link">Suministro de Material</a>
    </div>
  </li>
		  		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>EXPERIENCIA</a>
    <div ngbDropdownMenu >
		<a routerLink="/experiencia-obra"  class="nav-link">Obra Civil</a>
		<a routerLink="/experiencia-acabados"  class="nav-link">Acabados</a>
		<a routerLink="/experiencia-movimiento-tierra"  class="nav-link">Movimiento de Tierra</a>
    </div>
  </li>
        <li class="nav-item" >
          <a routerLink="/contactanos"  class="nav-link">CONTACTANOS</a>
        </li>	
        <li class="nav-item" >
          <a routerLink="/nuestra-empresa"  class="nav-link">NUESTRA EMPRESA</a>
        </li>	
      </ul>
    </div>
  </div>
</nav>
<br>
<br>
<div appScrollspy [spiedTags]="['SECTION']">
   <!--START HOME-->
   <section class="section section bg-light" style="background-color:white !important" id="home">
      <div class="container-fluid">
         <div class="row" >
            <div class="col-sm-12 " >
               <img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/productos1.jpg">
            </div>
         </div>
         <br> <br>		
         <div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default">Sistema de Acabados para Drywall</h2>
                     <br>
                  </div>
               </div>
               <span style="color:black;font-size:30px">Para el sistema de construcción liviana, ofrecemos productos con aditivos especiales de alta calidad que proporcionan un excelente acabado y rápido secado que permiten ahorrar tiempo y dinero en la obra.</span>
               <br> <br>	
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
		 
		  <div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default letra2">Estuco Panel Masilla</h2>
					  <br> <br>
                     <div class="section-title-border2"></div>
                     <br>
                  </div>
               </div>
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
         <div class="row text-left">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-4 letra" >
               <div class="elementor-element elementor-element-e35054b elementor-column elementor-col-50 elementor-inner-column" data-id="e35054b" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-fdeeca1 elementor-widget elementor-widget-text-editor" data-id="fdeeca1" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p><span style="color: #e6bb39;">Descripción</span></p><p>Masilla Joint Compound de secado rápido de uso interior con base en resinas acrílicas lista para usar.</p><p><span style="color: #e6bb39;"><br>Ventajas</span></p><ul><li>Secado Rapido entre 3 y 5 horas.</li><li>Optima adherencia de la cinta.</li><li>Acabado terso y blanco.</li><li>Bajo olor.</li><li>Buena trabajabilidad, baja contracción al secar, excelente rendimiento.</li><li>No se fisura.</li><li>Fácil de lijar</li></ul><div><p><span style="color: #e6bb39;"><br><span style="letter-spacing: 0em;">Super</span><span style="letter-spacing: 0em;">ficies de aplicación&nbsp;</span></span></p><p>Acabado en interiores en el tratamiento y resane de juntas en panel yeso, pegado de cintas de malla,cintas de papel, esquineros,rebordes, cielo rasos y en el masillado total de las láminas de panel yeso.</p><br><p><span style="color: #e6bb39;">Presentación</span></p><ul><li>Cuñete de 28 kg</li><li>Balde de 14 kg</li><li>Galon de 5,6 kg</li><li>Cuarto de galon 1,5 kg</li></ul></div></div>
				</div>
				</div>
						</div>
			</div>
		</div>
            </div>
            <div class="col-sm-4 text-center" >
               <img style='height: 400px; width: 400px; object-fit: contain'src="assets/images/estuco1.png">
               <br><br>
               <a href="assets/documentos/Estucopanel-Masilla-Secado-Rápido-Impadoc.pdf" class=" btn btn-custom" style="border-style: solid;" download>
               <i aria-hidden="true" class="ics fas fa-download elementor-button-text" style="color:#5D5552;" ></i>
               <span class="elementor-button-text"> DESCARGAR FICHA TÉCNICA</span>
               </a>
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
         <br><br>


		<div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default letra2">Estuco Panel Secado Rápido</h2>
                     <div class="section-title-border2"></div>
                     <br>
                  </div>
               </div>
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
         <div class="row text-left">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-4 letra" >
               <div class="elementor-element elementor-element-924ede6 elementor-column elementor-col-50 elementor-inner-column" data-id="924ede6" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-4d3c0a7 elementor-widget elementor-widget-text-editor" data-id="4d3c0a7" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p style="text-align: left;"><span style="color: #e6bb39;">Descripción</span></p>
<p>El Estucopanel es una masilla en polvo de color blanco fabricado con aglomerantes minerales y aditivos especiales, que le permiten un óptimo desempeño en sistemas de altos rendimientos y bajo costo. Acabado de construcción liviana.</p>
<p><span style="color: #e6bb39;"><br>Ventajas</span></p>
<ul>
<li>Gran capacidad adherente.</li>
<li>Secado extendido 90 minutos que permite trabajar areas grandes dando un mayor tiempo para trabajar.</li>
<li>Multiproposito permite encitar, llenar, dar pulimento a las laminas de panel yeso y estampillar (Revoque en seco).</li>
</ul>
<div>&nbsp;</div>
<div>
<p><span style="color: #e6bb39;">Superficie de Aplicación</span></p><p><span style="color: #e6bb39;"></span></p>
</div>
<ul>
<li>Usos en interiores sobre laminas de panel de yeso, cartón yeso y fibrocemento.</li>
<li>Permite pegar efectivamente placas de panel yeso sobre diferentes sustratos: muros en concreto vaciado o prefabricado , muros en ladrillos o bloques de hormigón ( revoque en seco o sistema de estampillado).</li>
</ul>
<div><span style="color: #e6bb39;">Presentación</span></div>
<ul>
<li>Material en polvo</li>
<li>Saco de 25&nbsp;Kg</li>
</ul></div>
				</div>
				</div>
						</div>
			</div>
		</div>
                  <br><br>

            </div>
            <div class="col-sm-4 text-center" >
               <img style='height: 400px; width: 400px; object-fit: contain'src="assets/images/estuco2.jpg">
               <br><br>
               <a href="assets/documentos/Estucopanel-En-Polvo-Secado-Rápido-Impadoc.pdf" class=" btn btn-custom" style="border-style: solid;" download>
               <i aria-hidden="true" class="ics fas fa-download elementor-button-text" style="color:#5D5552;" ></i>
               <span class="elementor-button-text"> DESCARGAR FICHA TÉCNICA</span>
               </a>
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
         <br><br>

<div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default letra2">Estuco Panel Secado Extendido</h2>
					  <br> <br>
                     <div class="section-title-border2"></div>
                     <br>
                  </div>
               </div>
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
         <div class="row text-left">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-4 letra" >
               <div class="elementor-element elementor-element-abd0304 elementor-column elementor-col-50 elementor-inner-column" data-id="abd0304" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-a5706a4 elementor-widget elementor-widget-text-editor" data-id="a5706a4" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><h3><span style="font-family: 'Encode Sans Expanded', sans-serif; font-size: 25px; font-weight: 400; letter-spacing: 0em; color: #e6bb39;">Descripción</span></h3><div><span style="color: #7a7a7a; font-family: 'Encode Sans Expanded', sans-serif; font-size: 25px; font-weight: 400; letter-spacing: 0em;">&nbsp;</span></div><p>El Estucopanel es una masilla en polvo de color blanco fabricado con aglomerantes minerales y aditivos especiales, que le permiten un óptimo desempeño en sistemas de altos rendimientos y bajo costo. Acabado de construcción liviana.</p><p><span style="color: #e6bb39;"><br>Ventajas</span></p><ul><li>Gran capacidad adherente.</li><li>Secado extendido 90 minutos que permite trabajar areas grandes dando un mayor tiempo para trabajar.</li><li>Multiproposito permite encitar, llenar, dar pulimento a las laminas de panel yeso y estampillar (Revoque en seco).</li></ul><div>Superficie de Aplicación</div><div>&nbsp;</div><ul><li>Usos en interiores sobre laminas de panel de yeso, cartón yeso y fibrocemento.</li><li>Permite pegar efectivamente placas de panel yeso sobre diferentes sustratos: muros en concreto vaciado o prefabricado , muros en ladrillos o bloques de hormigón ( revoque en seco o sistema de estampillado).&nbsp;</li></ul><div><span style="color: #e6bb39;">Presentación</span></div><div>&nbsp;</div><ul><li>Material en polvo</li><li>Saco de 25&nbsp;Kg</li></ul></div>
				</div>
				</div>
						</div>
			</div>
		</div>
            </div>
            <div class="col-sm-4 text-center" >
               <img style='height: 400px; width: 400px; object-fit: contain'src="assets/images/estuco3.jpg">
               <br><br>
               <a href="assets/documentos/Estucopanel-Masilla-Secado-Rápido-Impadoc.pdf" class=" btn btn-custom" style="border-style: solid;" download>
               <i aria-hidden="true" class="ics fas fa-download elementor-button-text" style="color:#5D5552;" ></i>
               <span class="elementor-button-text"> DESCARGAR FICHA TÉCNICA</span>
               </a>
            </div>
            <div class="col-sm-2" >
            </div>
         </div>





         <br><br>
      </div>
   </section>
        <div class="container-fluid" >
       
	   <div class="row text-center align-middle" >
         <div class="col-sm-12 text-center " style="background-color: #5D5552;
   height:400px;background-position: center center;" >
   
   <div class="row text-center" style="height:100px;" >
   </div>
   <div class="row text-center " >
   
	   <div class="col-sm-12">
	   <h2 class="elementor-heading-title centered">CONOCE MÁS DE IMPADOC</h2>
	   </div>
	   
   </div>
   
   <br><br>
      <div class="row text-center " >
   
	   <div class="col-sm-12" >
   			<a href="assets/documentos/Catálogo-PRODUCTOS-IMPADOC.pdf" class=" btn btn-custom" style="border-style: solid;" download>
			<i aria-hidden="true" class="ics fas fa-envelope elementor-button-text" style="color:#5D5552;" ></i>
			<span class="elementor-button-text contac"> DESCARGAR CATÁLOGO</span>
			</a>
			
	   </div>
	   
   </div>

   
         </div>
       </div>
     </div>
	 <section class="section" id="features">
  <div class="container">
  
   <div class="row text-center">
	   <div class="col-sm-12 ">
			<h2 class="elementor-heading-title elementor-size-default">Galeria de Videos</h2>
			<br>
			<div class="section-title-border margin-t-70"></div>
	   </div>
   </div>
  <br><br>
    <div class="row align-items-center">
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/GFI-82d07KY?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/9LuB5zniHBU?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/SqUwLMY4780?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
    </div>
	
	<div class="row align-items-center">
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/9qTiMt1MWI8?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/-uhGlP4uSjI?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/SrnAbkQWt-8?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
    </div>
	<br>

	<br>
  </div>
</section>
   <!--END HOME-->
   <app-footer></app-footer>
</div>