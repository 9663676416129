<!-- STRAT NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img   src="assets/images/logo.png">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" >
          <a routerLink="/"  class="nav-link">Inicio</a>
        </li>
		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>PRODUCTOS Y SERVICIOS</a>
    <div ngbDropdownMenu >
			  <li ngbDropdown class="nav-item">
    <a href (click)="false" class="nav-link" ngbDropdownToggle>Productos IMPADOC</a>
    <div ngbDropdownMenu>
		<a routerLink="/interiores"  class="nav-link">Sistema de Acabado Para Interiores</a>
		<a routerLink="/drywall"  class="nav-link">Sistema de Acabado Para Drywall</a>
		<a routerLink="/exteriores"  class="nav-link">Sistema de Acabado Para Exteriores</a>
		<a routerLink="/adhesivos"  class="nav-link">Adhesivos Para Empaque</a>
    </div>
	
  </li>
		<a routerLink="/proyectos"  class="nav-link">Gestión de Proyectos</a>
		<a routerLink="/obra-civil"  class="nav-link">Obra Civil</a>
		<a routerLink="/acabados"  class="nav-link">Acabados</a>
		<a routerLink="/maquinaria"  class="nav-link">Alquiler de Maquinaria</a>
		<a routerLink="/suministro"  class="nav-link">Suministro de Material</a>
    </div>
  </li>
		  		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>EXPERIENCIA</a>
    <div ngbDropdownMenu >
		<a routerLink="/experiencia-obra"  class="nav-link">Obra Civil</a>
		<a routerLink="/experiencia-acabados"  class="nav-link">Acabados</a>
		<a routerLink="/experiencia-movimiento-tierra"  class="nav-link">Movimiento de Tierra</a>
    </div>
  </li>
        <li class="nav-item" >
          <a routerLink="/contactanos"  class="nav-link">CONTACTANOS</a>
        </li>	
        <li class="nav-item" >
          <a routerLink="/nuestra-empresa"  class="nav-link">NUESTRA EMPRESA</a>
        </li>	
      </ul>
    </div>
  </div>
</nav>
<br>
<br>
<div appScrollspy [spiedTags]="['SECTION']">
   <!--START HOME-->
      <div class="container-fluid section">
         <div class="row" >
            <div class="col-sm-12 " >
               <img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/experiencia2.jpg">
            </div>
         </div>
		 
		 
         <br> <br>		
         
	<br>
  </div>


  <div class="container">
  
   <div class="row text-center">
	   <div class="col-sm-12 ">
			<h2 class="elementor-heading-title elementor-size-default">Movimientos de Tierra</h2>
			<br>
			<div class="section-title-border margin-t-70"></div>
	   </div>
   </div>
  <br><br>
    <div class="row align-items-center">
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Apartamentos Terracota</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Proyecto ubicado en el Carmen de Viboral, municipio del Oriente Antioqueño. Torre de 8 pisos, 2 locales con acceso independiente a los apartamentos, 2  niveles de parqueaderos privados y una vía para uso exclusivo de la unidad con parqueaderos descubiertos de visitantes.</p>
      </div>
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Unidad Deportiva Maria Auxiliadora</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images2">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Adecuación del terreno, excavaciones, construcción de muro de contención, andenes, escaleras de acceso al escenario y el riego y compactación del terreno para la placa deportiva. </p>
      </div>
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Parque Recreativo Confama</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images3">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Retiro y transporte de material, construcción de puente provisional para el retiro del mismo, suministro de afirmado para generar estabilidad en la vía de acceso de cargue, construcción de un farillón con especificaciones técnicas para la contención del nuevo material a sedimentar.</p>
      </div>	  
    </div>
	<br>
	<br>
  </div>



   <!--END HOME-->
   <app-footer></app-footer>
</div>