import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css', "/node_modules/font-awesome/css/font-awesome.css"]
})
export class ContactoComponent implements OnInit {

	firstname: string;
	secondname: string;
	email: string;
	currentSection:string  = 'home';

checkData(){ 
}

  constructor() { }

  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  ngOnInit() { window.scrollTo(0, 0);
	  this.currentSection = 'home';
  }

}
