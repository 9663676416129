<!-- STRAT NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img   src="assets/images/logo.png">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" >
          <a routerLink="/"  class="nav-link">Inicio</a>
        </li>
		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>PRODUCTOS Y SERVICIOS</a>
    <div ngbDropdownMenu >
			  <li ngbDropdown class="nav-item">
    <a href (click)="false" class="nav-link" ngbDropdownToggle>Productos IMPADOC</a>
    <div ngbDropdownMenu>
		<a routerLink="/interiores"  class="nav-link">Sistema de Acabado Para Interiores</a>
		<a routerLink="/drywall"  class="nav-link">Sistema de Acabado Para Drywall</a>
		<a routerLink="/exteriores"  class="nav-link">Sistema de Acabado Para Exteriores</a>
		<a routerLink="/adhesivos"  class="nav-link">Adhesivos Para Empaque</a>
    </div>
	
  </li>
		<a routerLink="/proyectos"  class="nav-link">Gestión de Proyectos</a>
		<a routerLink="/obra-civil"  class="nav-link">Obra Civil</a>
		<a routerLink="/acabados"  class="nav-link">Acabados</a>
		<a routerLink="/maquinaria"  class="nav-link">Alquiler de Maquinaria</a>
		<a routerLink="/suministro"  class="nav-link">Suministro de Material</a>
    </div>
  </li>
		  		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>EXPERIENCIA</a>
    <div ngbDropdownMenu >
		<a routerLink="/experiencia-obra"  class="nav-link">Obra Civil</a>
		<a routerLink="/experiencia-acabados"  class="nav-link">Acabados</a>
		<a routerLink="/experiencia-movimiento-tierra"  class="nav-link">Movimiento de Tierra</a>
    </div>
  </li>
        <li class="nav-item" >
          <a routerLink="/contactanos"  class="nav-link">CONTACTANOS</a>
        </li>	
        <li class="nav-item" >
          <a routerLink="/nuestra-empresa"  class="nav-link">NUESTRA EMPRESA</a>
        </li>	
      </ul>
    </div>
  </div>
</nav>
<br>
<br>
<div appScrollspy [spiedTags]="['SECTION']">
   <!--START HOME-->
      <div class="container-fluid section">
         <div class="row" >
            <div class="col-sm-12 " >
               <img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/experiencia2.jpg">
            </div>
         </div>
		 
		 
         <br> <br>		
         
	<br>
  </div>


  <div class="container">
  
   <div class="row text-center">
	   <div class="col-sm-12 ">
			<h2 class="elementor-heading-title elementor-size-default">Acabados</h2>
			<br>
			<div class="section-title-border margin-t-70"></div>
	   </div>
   </div>
  <br><br>
    <div class="row align-items-center">
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Hotel San Antonio</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Hotel con habitaciones amplias y zonas comunes amenas para el bienestar de los huéspedes, pensado para la personas que gustan de un lugar moderno y elegante para que puedan disfrutar una vista privilegiada.</p>
      </div>
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Apartamentos Piedra Verde</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images2">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Este proyecto edificado en la Ceja – Antioquia , consta de un complejo de apartamentos, nuestro aporte ayudo a el enlucimiento de los acabados, realizando la intervención de los muros con nuestros estucos.</p>
      </div>
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Torre Andalucia</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images3">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Torre de apartamentos completamente acabados, este proyecto es ideal para personas que buscan un espacio urbano pero con las bondades de un ambiente campestre a sus alrededores.</p>
      </div>	  
    </div>
	<br>
	<div class="row align-items-center">
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Apartamentos Terracota</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images4">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Proyecto ubicado en el Carmen de Viboral, municipio del Oriente Antioqueño. Torre de 8 pisos, 2 locales con acceso independiente a los apartamentos, 2  niveles de parqueaderos privados y una vía para uso exclusivo de la unidad con parqueaderos descubiertos de visitantes.</p>
      </div>
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Apartamentos San Marcos</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images5">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Proyecto ubicado en el municipio de la Ceja. Se realizó actividades de revoques interiores y fachadas, acabados en apartamentos y zonas comunes. Construcción liviana en Drywall y Superboard, para cielos y muros divisorios. Demarcación de parqueaderos y pintura en fachadas.</p>
      </div>
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">CDI Casas del Mar</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images6">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Mejoramiento del entorno social y de la Primera Infancia, mediante la generación de espacios adecuados y dignos para la promoción del desarrollo integral de niños y niñas, con la construcción de un Centro de Desarrollo Infantil en el sector Casas del Mar del municipio de Rionegro.​</p>
      </div>	  
    </div>
	<br>
	<br>
  </div>



   <!--END HOME-->
   <app-footer></app-footer>
</div>