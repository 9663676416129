<!-- STRAT NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img   src="assets/images/logo.png">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" >
          <a routerLink="/"  class="nav-link">Inicio</a>
        </li>
		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>PRODUCTOS Y SERVICIOS</a>
    <div ngbDropdownMenu >
			  <li ngbDropdown class="nav-item">
    <a href (click)="false" class="nav-link" ngbDropdownToggle>Productos IMPADOC</a>
    <div ngbDropdownMenu>
		<a routerLink="/interiores"  class="nav-link">Sistema de Acabado Para Interiores</a>
		<a routerLink="/drywall"  class="nav-link">Sistema de Acabado Para Drywall</a>
		<a routerLink="/exteriores"  class="nav-link">Sistema de Acabado Para Exteriores</a>
		<a routerLink="/adhesivos"  class="nav-link">Adhesivos Para Empaque</a>
    </div>
	
  </li>
		<a routerLink="/proyectos"  class="nav-link">Gestión de Proyectos</a>
		<a routerLink="/obra-civil"  class="nav-link">Obra Civil</a>
		<a routerLink="/acabados"  class="nav-link">Acabados</a>
		<a routerLink="/maquinaria"  class="nav-link">Alquiler de Maquinaria</a>
		<a routerLink="/suministro"  class="nav-link">Suministro de Material</a>
    </div>
  </li>
		  		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>EXPERIENCIA</a>
    <div ngbDropdownMenu >
		<a routerLink="/experiencia-obra"  class="nav-link">Obra Civil</a>
		<a routerLink="/experiencia-acabados"  class="nav-link">Acabados</a>
		<a routerLink="/experiencia-movimiento-tierra"  class="nav-link">Movimiento de Tierra</a>
    </div>
  </li>
        <li class="nav-item" >
          <a routerLink="/contactanos"  class="nav-link">CONTACTANOS</a>
        </li>	
        <li class="nav-item" >
          <a routerLink="/nuestra-empresa"  class="nav-link">NUESTRA EMPRESA</a>
        </li>	
      </ul>
    </div>
  </div>
</nav>
<br>
<br>
<div appScrollspy [spiedTags]="['SECTION']">
   <!--START HOME-->
      <div class="container-fluid section">
         <div class="row" >
            <div class="col-sm-12 " >
               <img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/experiencia2.jpg">
            </div>
         </div>
		 
		 
         <br> <br>		
         
	<br>
  </div>


  <div class="container">
  
   <div class="row text-center">
	   <div class="col-sm-12 ">
			<h2 class="elementor-heading-title elementor-size-default">Obra Civil</h2>
			<br>
			<div class="section-title-border margin-t-70"></div>
	   </div>
   </div>
  <br><br>
    <div class="row align-items-center">
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Placa Deportiva Piedras Blancas</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Adecuación de la placa deportiva en la vereda Piedras Blancas del municipio de Guarne. Este proyecto consta con una placa deportiva cubierta.</p>
      </div>
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Placa Deportiva Hojas Anchas</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images2">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Adecuación de la placa deportiva en la vereda Hojas Anchas del municipio de Guarne. Construcción de cubierta para la placa existente.</p>
      </div>
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Placa Deportiva Cocorná</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images3">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Intervención de la cancha de fútbol principal del municipio de Cocorná (Ant). </p>
      </div>	  
    </div>
	<br>
	<div class="row align-items-center">
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Escenarios Deportivos Guatape</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images4">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Intervención de la unidad deportiva del municipio de Guatape (Ant). Remodelación de la piscina principal, camerinos y zonas aledañas.</p>
      </div>
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Unidad Deportiva Maria Auxiliadora</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images5">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Adecuación del terreno, excavaciones, construcción de muro de contención, andenes, escaleras de acceso al escenario y el riego y compactación del terreno para la placa deportiva. </p>
      </div>
      <div class="col-sm-4 text-center border_frame">
		<h2 class="title3">Unidad Deportiva San Francisco</h2>
		<ngb-carousel #carousel [interval]="10000" [pauseOnHover]="pauseOnHover" (slide)="onSlide($event)">
		  <ng-template ngbSlide *ngFor="let img of images6">
				<img style='height: 100%; width: 100%; object-fit: contain'[src]="img">
		  </ng-template>
		</ngb-carousel>
		<br>
	    <p>Adecuación del terreno, con la construcción de los filtros de la cancha de fútbol. Edificación de cárcamos y canales para el drenaje de las aguas.</p>
      </div>	  
    </div>
	<br>
	<br>
  </div>



   <!--END HOME-->
   <app-footer></app-footer>
</div>