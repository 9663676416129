   <!--START WORK-US-->
   <section class="section" id="work">
     <div class="container-fluid" style="height:900px">
       
	<div class="row text-center">
	   <div class="col-sm-12 ">
			<h2 class="elementor-heading-title elementor-size-default">TRABAJA CON NOSOTROS</h2>
			<br>
			<div class="section-title-border margin-t-70"></div>
	   </div>
   </div>
     <br><br>
	   <div class="row text-center align-middle" >
         <div class="col-sm-12 text-center " style="background-image: url('assets/images/trabaja.jpg');    background-repeat:no-repeat;
   background-size:cover;
   height:700px;background-position: center center;" >
   
   <div class="row text-center" style="height:250px;" >
   </div>
   <div class="row text-center " >
   
	   <div class="col-sm-12">
	   <h2 class="elementor-heading-title centered contac2">Estas interesado en trabajar
				con nosotros?</h2>
	   </div>
	   
   </div>
   
   <br><br>
      <div class="row text-center " >
   
	   <div class="col-sm-12">
   			<a href="/contactanos" class=" btn btn-custom">
			<i aria-hidden="true" class="ics2 fa fa-suitcase elementor-button-text" ></i>
			<span class="elementor-button-text"> QUIERO TRABAJAR EN EVOLUCIÓN</span>
			</a>
	   </div>
	   
   </div>
   

   
   
         </div>
       </div>
     </div>
   </section>
   <!--END ABOUT-US-->
