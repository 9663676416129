import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { Index1Component } from './index1/index1.component';
import { PoliticaComponent } from './politica/politica.component';
import { ContactoComponent } from './contacto/contacto.component';

import { EmpresaComponent } from './empresa/empresa.component';
import { Obra1Component } from './obra1/obra1.component';
import { AcabadosComponent } from './acabados/acabados.component';
import { MaquinariaComponent } from './maquinaria/maquinaria.component';
import { SuministroComponent } from './suministro/suministro.component';
import { ProyectosComponent } from './proyectos/proyectos.component';
import { InterioresComponent } from './interiores/interiores.component';
import { DrywallComponent } from './drywall/drywall.component';
import { ExterioresComponent } from './exteriores/exteriores.component';
import { AdhesivosComponent } from './adhesivos/adhesivos.component';
import { ExperienciaObraComponent } from './experienciaobra/experienciaobra.component';
import { ExperienciaAcabadosComponent } from './experienciaacabados/experienciaacabados.component';
import { ExperienciaTierraComponent } from './experienciatierra/experienciatierra.component';

import { ProductosComponent } from './productos/productos.component';
import { ExperienciaComponent } from './experiencia/experiencia.component';
import { BlogComponent } from './blog/blog.component';



import { PagesRoutingModule } from './pages-routing.module';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [Index1Component, LoginComponent, SignupComponent, PasswordForgotComponent, PoliticaComponent, ContactoComponent,
  EmpresaComponent, Obra1Component, AcabadosComponent, MaquinariaComponent, SuministroComponent, ProyectosComponent,
  InterioresComponent, DrywallComponent, ExterioresComponent, AdhesivosComponent, ExperienciaObraComponent,
  ExperienciaAcabadosComponent, ExperienciaTierraComponent, ProductosComponent, ExperienciaComponent, BlogComponent],
  imports: [
    CommonModule,
    PagesRoutingModule,
    SharedModule,
    NgbModule,
    NgxYoutubePlayerModule.forRoot(),
    ScrollToModule.forRoot()
  ]
})
export class PagesModule { }
