<!-- STRAT NAVBAR -->
<nav class="navbar navbar-expand-lg fixed-top navbar-white navbar-custom sticky sticky-dark">
  <div class="container">
    <!-- LOGO -->
    <a class="navbar-brand logo text-uppercase" href="/">
      <img   src="assets/images/logo.png">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav navbar-center" id="mySidenav">
        <li class="nav-item" >
          <a routerLink="/"  class="nav-link">Inicio</a>
        </li>
		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>PRODUCTOS Y SERVICIOS</a>
    <div ngbDropdownMenu >
			  <li ngbDropdown class="nav-item">
    <a href (click)="false" class="nav-link" ngbDropdownToggle>Productos IMPADOC</a>
    <div ngbDropdownMenu>
		<a routerLink="/interiores"  class="nav-link">Sistema de Acabado Para Interiores</a>
		<a routerLink="/drywall"  class="nav-link">Sistema de Acabado Para Drywall</a>
		<a routerLink="/exteriores"  class="nav-link">Sistema de Acabado Para Exteriores</a>
		<a routerLink="/adhesivos"  class="nav-link">Adhesivos Para Empaque</a>
    </div>
	
  </li>
		<a routerLink="/proyectos"  class="nav-link">Gestión de Proyectos</a>
		<a routerLink="/obra-civil"  class="nav-link">Obra Civil</a>
		<a routerLink="/acabados"  class="nav-link">Acabados</a>
		<a routerLink="/maquinaria"  class="nav-link">Alquiler de Maquinaria</a>
		<a routerLink="/suministro"  class="nav-link">Suministro de Material</a>
    </div>
  </li>
		  		  <li ngbDropdown class="nav-item"  >
    <a href (click)="false" class="nav-link"  ngbDropdownToggle>EXPERIENCIA</a>
    <div ngbDropdownMenu >
		<a routerLink="/experiencia-obra"  class="nav-link">Obra Civil</a>
		<a routerLink="/experiencia-acabados"  class="nav-link">Acabados</a>
		<a routerLink="/experiencia-movimiento-tierra"  class="nav-link">Movimiento de Tierra</a>
    </div>
  </li>
        <li class="nav-item" >
          <a routerLink="/contactanos"  class="nav-link">CONTACTANOS</a>
        </li>	
        <li class="nav-item" >
          <a routerLink="/nuestra-empresa"  class="nav-link">NUESTRA EMPRESA</a>
        </li>	
      </ul>
    </div>
  </div>
</nav>
<br>
<br>
<div appScrollspy [spiedTags]="['SECTION']">
   <!--START HOME-->
   <section class="section section bg-light" style="background-color:white !important" id="home">
      <div class="container-fluid">
         <div class="row" >
            <div class="col-sm-12 " >
               <img style='height: 100%; width: 100%; object-fit: contain'src="assets/images/productos1.jpg">
            </div>
         </div>
         <br> <br>		
         <div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default">Sistema de Acabados para Interiores</h2>
                     <br>
                  </div>
               </div>
               <span style="color:black;font-size:30px">Para el sistema de acabados de interiores contamos con productos de alta calidad y tecnología para la nivelación y enlucimiento de muros.</span>
               <br> <br>	
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
		 
		  <div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default letra2">Rellenolisto Interiores</h2>
					  <br> <br>
                     <div class="section-title-border2"></div>
                     <br>
                  </div>
               </div>
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
         <div class="row text-left">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-4 letra" >
               <div class="elementor-element elementor-element-e5024ad elementor-column elementor-col-50 elementor-inner-column" data-id="e5024ad" data-element_type="column">
                  <div class="elementor-column-wrap  elementor-element-populated">
                     <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-b57889d elementor-widget elementor-widget-text-editor" data-id="b57889d" data-element_type="widget" data-widget_type="text-editor.default">
                           <div class="elementor-widget-container">
                              <div class="elementor-text-editor elementor-clearfix">
                                 <p><span style="color: #e6bb39;">Descripción</span></p>
                                 <p>El Rellenolisto Interiores es un producto en polvo de color grisáceo sustituto del repello tradicional, constituido por minerales de alta pureza y aditivos de última tecnología que le confiere características especiales para un óptimo desempeño y economía.</p>
                                 <br>
                                 <p><span style="color: #e6bb39;">Ventajas&nbsp;</span></p>
                                 <ul>
                                    <li>Fácil de aplicar, rápido y limpio.</li>
                                    <li>Viene en dos presentaciones, Gruesa y Fina, las cuales permiten dar dos tipos de acabado a las superficies.</li>
                                    <li>Secado rápido que permiten rellenar y estucar en el mismo día optimizando el tiempo en la obra.</li>
                                    <li>Bajo consumo de agua y desperdicio&nbsp;</li>
                                 </ul>
                                 <div><span style="color: #e6bb39;">Superficie de Aplicación</span></div>
                                 <div>Ladrillo común, ladrillo farol, ladrillo estructural, bloques de hormigón,bloques prefabricados y placas de concreto vaciado, siempre en ambientes interiores.</div>
                                 <div>&nbsp;</div>
                                 <div><span style="color: #e6bb39;">Presentación</span></div>
                                 <div>
                                    <ul>
                                       <li>Material en polvo</li>
                                       <li>Saco de&nbsp; kg</li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <br><br>
               </div>
            </div>
            <div class="col-sm-4 text-center" >
               <img style='height: 400px; width: 400px; object-fit: contain'src="assets/images/relleno.jpg">
               <br><br>
               <a href="assets/documentos/Rellenolisto-Impadoc.pdf" class=" btn btn-custom" style="border-style: solid;" download>
               <i aria-hidden="true" class="ics fas fa-download elementor-button-text" style="color:#5D5552;" ></i>
               <span class="elementor-button-text"> DESCARGAR FICHA TÉCNICA</span>
               </a>
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
         <br><br>


		<div class="row">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-8 text-justify" >
               <div class="elementor-element elementor-element-1cae93e elementor-hidden-desktop elementor-widget elementor-widget-heading" data-id="1cae93e" data-element_type="widget" data-widget_type="heading.default">
                  <div class="elementor-widget-container">
                     <h2 class="elementor-heading-title elementor-size-default letra2">Estucolisto</h2>
                     <div class="section-title-border2"></div>
                     <br>
                  </div>
               </div>
               <br> <br>	  
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
		 
         <div class="row text-left">
            <div class="col-sm-2" >
            </div>
            <div class="col-sm-4 letra" >
               <div class="elementor-element elementor-element-51b21f0 elementor-column elementor-col-50 elementor-inner-column" data-id="51b21f0" data-element_type="column">
			<div class="elementor-column-wrap  elementor-element-populated">
					<div class="elementor-widget-wrap">
				<div class="elementor-element elementor-element-e9ecb9e elementor-widget elementor-widget-text-editor" data-id="e9ecb9e" data-element_type="widget" data-widget_type="text-editor.default">
				<div class="elementor-widget-container">
					<div class="elementor-text-editor elementor-clearfix"><p><span style="color: #e6bb39;">Descripción</span></p><p>El Estucolisto es un producto en polvo de color blanco diseñado para dar acabados en la construcción, fabricado industrialmente con ligantes, calizas de alta pureza y aditivos asegurando acabados finos y durables con mayor economía de última tecnología</p><p><span style="color: #e6bb39;">Ventajas&nbsp;</span></p><ul><li>Resistencia, gran trabajabilidad y adherencia.</li><li>Fácil de prepara y aplicar, solo requiere adicionar agua y mezclar.</li><li>Color blanco que conduce el consumo de pintura&nbsp;</li><li>Permite hacer dilataciones, filos, carteras ya también llenos hasta de&nbsp; cm sin fisurarse sin necesidad de adicionarle otros productos.</li><li>Se puede emparejar con codal para acabados totalmente planos.</li></ul><p><span style="color: #e6bb39;">Superficies de Aplicación</span></p><ul><li>Rellenolisto de interiores Impadoc</li><li>Revoques, repellos o pañetes, bloque de cemento.</li><li>Ladrillo farol, ladrillo liso o estriado, ladrillo común, ladrillos limpios y concretos vaciados.</li></ul><p><span style="letter-spacing: 0em; color: #e6bb39;">Presentación</span></p><ul><li>Material en Polvo&nbsp;</li><li>Saco de 10 a 25 kg</li></ul></div>
				</div>
				</div>
						</div>
			</div>
		</div>
                  <br><br>

            </div>
            <div class="col-sm-4 text-center" >
               <img style='height: 400px; width: 400px; object-fit: contain'src="assets/images/estucolisto-1.jpg">
               <br><br>
               <a href="assets/documentos/Estucolisto-Impadoc.pdf" class=" btn btn-custom" style="border-style: solid;" download>
               <i aria-hidden="true" class="ics fas fa-download elementor-button-text" style="color:#5D5552;" ></i>
               <span class="elementor-button-text"> DESCARGAR FICHA TÉCNICA</span>
               </a>
            </div>
            <div class="col-sm-2" >
            </div>
         </div>
         <br><br>






         <br><br>
      </div>
   </section>
        <div class="container-fluid" >
       
	   <div class="row text-center align-middle" >
         <div class="col-sm-12 text-center " style="background-color: #5D5552;
   height:400px;background-position: center center;" >
   
   <div class="row text-center" style="height:100px;" >
   </div>
   <div class="row text-center " >
   
	   <div class="col-sm-12">
	   <h2 class="elementor-heading-title centered">CONOCE MÁS DE IMPADOC</h2>
	   </div>
	   
   </div>
   
   <br><br>
      <div class="row text-center " >
   
	   <div class="col-sm-12" >
   			<a href="assets/documentos/Catálogo-PRODUCTOS-IMPADOC.pdf" class=" btn btn-custom" style="border-style: solid;" download>
			<i aria-hidden="true" class="ics fas fa-envelope elementor-button-text" style="color:#5D5552;" ></i>
			<span class="elementor-button-text contac"> DESCARGAR CATÁLOGO</span>
			</a>
			
	   </div>
	   
   </div>

   
         </div>
       </div>
     </div>
	 <section class="section" id="features">
  <div class="container">
  
   <div class="row text-center">
	   <div class="col-sm-12 ">
			<h2 class="elementor-heading-title elementor-size-default">Galeria de Videos</h2>
			<br>
			<div class="section-title-border margin-t-70"></div>
	   </div>
   </div>
  <br><br>
    <div class="row align-items-center">
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/GFI-82d07KY?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/9LuB5zniHBU?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/SqUwLMY4780?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
    </div>
	
	<div class="row align-items-center">
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/9qTiMt1MWI8?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/-uhGlP4uSjI?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
      <div class="col-sm-4 text-center">
		<iframe class="elementor-video-iframe" allowfullscreen="" title="Reproductor de vídeo youtube" src="https://www.youtube.com/embed/SrnAbkQWt-8?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
      </div>	  
    </div>
	<br>

	<br>
  </div>
</section>
   <!--END HOME-->
   <app-footer></app-footer>
</div>